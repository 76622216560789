// @flow

import compose from 'lodash/fp/compose';
import Alert from 'react-aaui/lib/alert';
import { getMessage } from 'services/localize/localeProvider';
import { mapError } from './mapError';

const alertError = compose(Alert.error, message => ({ message }), getMessage, mapError);

export const handleError = (httpStatus: number, message?: string) => {
  switch (httpStatus) {
    case 403:
      window.location.replace('/');
      break;
    case 400:
    case 500:
    default:
      alertError(message);
  }
};
