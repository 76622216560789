// @flow

import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import connect from '@active/awe-ui-core/lib/hoc/connect';
import { changeLanguage } from 'modules/app/duck';
import { signOut } from 'modules/user/duck';
import { UserRole } from 'modules/user/enum';
import {
  getLocale, getAvailableLanguages, getPassportLink, getPortalPath, hasUsedPortalPath,
} from 'modules/app/selector';
import { isWhiteLabelReady, getWhiteLabelLogo } from 'modules/whiteLabel/selector';
import { getUser } from 'modules/user/selector';
import prop from 'lodash/fp/prop';
import includes from 'lodash/includes';
import flow from 'lodash/fp/flow';
import Maybe, { maybe } from '@active/awe-ui-core/lib/fp/Maybe';
import L10nMessage from 'react-aaui/lib/shared/L10nMessage';
import ShowAt from 'react-aaui/lib/viewport/ShowAt';
import HideAt from 'react-aaui/lib/viewport/HideAt';
import PopUpMenu from 'react-aaui/lib/PopUpMenu/PopUpMenu';
import LanguageSelector from '@active/awe-ui-core/lib/components/LanguageSelector';
import HeaderDropDown from '@active/awe-ui-core/lib/components/HeaderDropDown';
import classNames from 'classnames';
import GlobalSearch from 'components/GlobalSearch/GlobalSearch';
import UmbrellaPortalController from 'components/UmbrellaPortalController/UmbrellaPortalController';
import SearchInput from 'react-aaui/lib/SearchInput';
import appendPortalPath from 'services/router/appendPortalPath';
import {
  fetchSearchEvent, fetchSearchPerson, handleChange, doSearch,
} from 'scenes/SearchResults/duck/duck';
import {
  ALL_EVENT_LIMIT,
  ALL_PERSON_LIMIT,
  EVENT_LIMIT,
  OFFSET,
  PERSON_LIMIT,
  TAB_EVENT,
  TAB_PARTICIPANT,
} from 'services/query/query';
import { getKeywords, getTabType } from 'scenes/SearchResults/duck/selector';

import smartSearch from 'hoc/smartSearch/smartSearch';
import injectL10n from 'react-aaui/lib/shared/injectL10n';
import RoleFilter from 'components/RoleFilter/RoleFilter';
import { navToPassport } from 'services/navigation/navigation';
import { parse, stringify } from 'query-string';
import { getEvent } from 'scenes/Participant/duck/selector';
import { getApplicationName } from 'scenes/Event/duck/selector';
import { isKioskMode } from 'services/kiosk/kiosk';

import resultsLogo from './logo-results.svg';
import HeaderLabel from './HeaderLabel/HeaderLabel';
import PopUpMenuContent from './PopUpMenuContent/PopUpMenuContent';
import './Header.scss';

export const DISPLAY_STATE_MINIMIZED = 'minimized';
export const DISPLAY_STATE_MAXMIZED = 'maxmized';
export const SCROLL_UP_THREHOLD = 50;
export const TRANSITION_DURATION = 300;

const MENU_ITEMS = [
  { url: '/', label: <L10nMessage id="app.header.menu.home" />, key: 'home' },
  { url: '/my-results', label: <L10nMessage id="app.header.menu.myResults" />, key: 'myResults' },
  {
    url: '/manage-results',
    label: <L10nMessage id="app.header.menu.manageResults" />,
    key: 'manageResults',
    roles: [ UserRole.TIMER ],
  },
];

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAffixed: false,
      displayState: '',
      transitioning: false,
      showSearchPopup: false,
      showMenuPopup: false,
    };
    this.props.history.listen(() => {
      if (this.state.showSearchPopup) {
        this.setState({ showSearchPopup: false });
      }
      if (this.state.showMenuPopup) {
        this.setState({ showMenuPopup: false });
      }
    });
  }

  componentDidMount() {
    this.watchScroll();
  }

  isHeaderInfoShow = (path: string) => {
    const { hasPortalPath } = this.props;

    return !(hasPortalPath || path === '/' || includes(path, 'manage-results'));
  }

  toggleSearchFlowOut = () => {
    this.setState(prev => ({
      showSearchPopup: !prev.showSearchPopup,
    }));
  }

  toggleMenuFlowOut = () => {
    this.setState(prev => ({
      showMenuPopup: !prev.showMenuPopup,
    }));
  }

  watchScroll() {
    this.logWindowHeight();
    window.addEventListener('scroll', this.handleWindowScroll);
  }

  handleWindowScroll = () => {
    const currentTop = this.getScrollYOffset();
    const headerHeight = this.getHeaderHeight();
    let isAffixed;
    let displayState;

    if (this.isChangingWindowHeight()) {
      this.logWindowHeight();
      return;
    }

    if (this.state.showSearchPopup || this.state.showMenuPopup) {
      return;
    }

    if (this.searchInputFocus) {
      return;
    }

    if (this.isScrollDown(this.lastTop, currentTop)) {
      isAffixed = currentTop > headerHeight || this.state.isAffixed;
      displayState = DISPLAY_STATE_MINIMIZED;
      delete this.startScrollUpTop;
    } else {
      if (!this.startScrollUpTop) {
        this.startScrollUpTop = currentTop;
      }
      isAffixed = this.startScrollUpTop > headerHeight && currentTop > 0;
      displayState = this.isContinueScrollUp(currentTop) ? DISPLAY_STATE_MAXMIZED : DISPLAY_STATE_MINIMIZED;
    }

    displayState = isAffixed ? displayState : '';
    if (this.shouldStartTransition(displayState)) {
      this.startTransition();
    }

    this.lastTop = currentTop;
    this.setState({
      isAffixed,
      displayState,
    });
  }

  logWindowHeight() {
    this.windowHeight = this.getWindowHeight();
  }

  isChangingWindowHeight() {
    return this.windowHeight !== this.getWindowHeight();
  }

  getWindowHeight() {
    return window.innerHeight;
  }

  isScrollDown(lastTop: number = 0, currentTop: number) {
    return lastTop < currentTop;
  }

  isContinueScrollUp(currentTop: number) {
    return this.startScrollUpTop - currentTop >= SCROLL_UP_THREHOLD;
  }

  getScrollYOffset() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  getHeaderHeight() {
    return maybe(0, prop('clientHeight'), Maybe.of(document.getElementById('app-header')));
  }

  shouldStartTransition(nextState) {
    const { displayState } = this.state;
    return nextState && displayState && nextState !== displayState;
  }

  startTransition() {
    if (this.transitionTransaction) {
      clearTimeout(this.transitionTransaction);
      delete this.transitionTransaction;
    }
    this.setState({
      transitioning: true,
    });
    this.transitionTransaction = setTimeout(() => this.setState({ transitioning: false }), TRANSITION_DURATION);
  }

  goToLogin = () => {
    navToPassport(this.props.passportLink);
  }

  getLanguage() {
    const { availableLanguages } = this.props;

    return maybe(
      null,
      ls => ls.map(l => ({
        text: this.props.l10n.formatMessage(l),
        value: l,
      })),
      Maybe.of(availableLanguages),
    );
  }

  renderAccountInfo() {
    if (this.props.user && this.props.user.enterprisePersonId) {
      const menuContent = (
        <a onClick={this.props.onSignOutClick}>
          <L10nMessage id="app.header.sign.out" />
        </a>
      );

      return (
        <HeaderDropDown
          isShowArrow
          menuContent={menuContent}
          headerDropDownItemClass="app-header-item account-item item">
          <L10nMessage id="app.header.hi" />
          ,
          {this.props.user.firstName}
        </HeaderDropDown>
      );
    }
    return (
      <div className="app-header-item account-item item">
        <a onClick={this.goToLogin}>
          <L10nMessage id="app.login.header.title" />
        </a>
      </div>
    );
  }

  renderMenuItems() {
    const isMenuPopupShown = this.state.showMenuPopup;
    return MENU_ITEMS.map((menuItem) => {
      const link = (
        <NavLink
          exact
          className="menu-item"
          key={menuItem.key}
          activeClassName="active"
          to={menuItem.url}
          onClick={() => isMenuPopupShown && this.toggleMenuFlowOut()}>
          {menuItem.label}
        </NavLink>
      );
      return menuItem.roles ? (
        <RoleFilter key={menuItem.key} requiredRoles={menuItem.roles}>{link}</RoleFilter>
      ) : link;
    });
  }

  onSearch = () => {
    const {
      keywords, history, tabType, onNav,
    } = this.props;
    if (keywords.trim() !== '') {
      this.props.doSearch();
      if (history.location.pathname === '/search') {
        switch (tabType) {
          case TAB_PARTICIPANT:
            this.props.fetchSearchPerson(keywords, OFFSET, PERSON_LIMIT);
            break;
          case TAB_EVENT:
            this.props.fetchSearchEvent(keywords, OFFSET, EVENT_LIMIT);
            break;
          default:
            this.props.fetchSearchPerson(keywords, OFFSET, ALL_PERSON_LIMIT);
            this.props.fetchSearchEvent(keywords, OFFSET, ALL_EVENT_LIMIT);
        }
      }
      onNav();
    }
  }

  handleChange = (value: string) => {
    this.props.handleChange(value);
  }

  handleSearch = (keywords: string) => {
    if (keywords.trim() !== '') {
      this.toggleSearchFlowOut();
      this.onSearch();
    }
    this.props.handleChange(keywords);
  }

  onSearchInputFocus = () => {
    this.searchInputFocus = true;
  }

  onSearchInputBlur = () => {
    this.searchInputFocus = false;
  }

  render() {
    const {
      user,
      locale,
      l10n,
      onChangeLanguage,
      whiteLabelReady,
      eventLogo,
      keywords,
      history,
      onSignOutClick,
      portalPath,
      hasPortalPath,
      event,
      applicationName,
    } = this.props;

    const {
      isAffixed, displayState, transitioning, showSearchPopup,
      showMenuPopup,
    } = this.state;
    const languages = this.getLanguage();
    const path = history.location.pathname;
    const query = flow(
      prop('location.search'),
      parse,
    )(history);
    const isKiosk = isKioskMode(history) && path.match(/^\/(events|participants)\/(.*?)/);

    const logo = (newPage: boolean = false) => {
      if (whiteLabelReady) {
        if (eventLogo && !hasPortalPath && !isKiosk) {
          return <img src={eventLogo} alt="logo" className="app-header-logo" />;
        }

        let href = '/';
        if (hasPortalPath) {
          href = appendPortalPath(portalPath, '');
        } else if (isKiosk) {
          const appName = applicationName || prop('applicationName')(event);
          href = `/events/${appName}?${stringify(query)}`;
        }

        return (
          <a
            target={newPage ? '_blank' : '_self'}
            rel="noopener noreferrer"
            href={href}
            className="app-header-logo-link">
            <img src={eventLogo || resultsLogo} alt="logo" className="app-header-logo" />
          </a>
        );
      }

      return null;
    };

    const popUpMenuContentParams = {
      isMenuPopupShown: showMenuPopup,
      MENU_ITEMS,
      toggleMenuFlowOut: this.toggleMenuFlowOut,
      user,
      locale,
      onSignOutClick,
      goToLogin: this.goToLogin,
      l10n,
      languages,
      onChangeLanguage,
      showLanguageOnly: isKiosk,
    };

    return (
      <Fragment>
        <div
          id="app-header"
          className={classNames('app-header', {
            'app-header-home': !this.isHeaderInfoShow(path),
            'app-header--affixed': isAffixed,
            [displayState]: !!displayState,
            transitioning,
          })}>
          <div className="header--desktop__infobar header row">
            <div className="header--desktop__wrap col-12">
              <HideAt lgAndAbove>
                <div className="col-4">
                  <PopUpMenu
                    toggleFlowOut={this.toggleMenuFlowOut}
                    shown={showMenuPopup}
                    renderMenuHeader={logo}
                    controllerIcon="icon-menu">
                    <PopUpMenuContent {...popUpMenuContentParams} />
                  </PopUpMenu>
                </div>
              </HideAt>
              <HideAt lgAndAbove>
                <div className="header--desktop__logo logo col-4">
                  {logo()}
                </div>
              </HideAt>
              <HideAt lgAndAbove>
                <div className="header-mobile--search col-4">
                  {
                    !hasPortalPath && path !== '/' && path.match(/^\/(events|participants)\/(.*?)/) === null
                      ? <PopUpMenu
                        toggleFlowOut={this.toggleSearchFlowOut}
                        shown={showSearchPopup}
                        renderMenuHeader={logo}
                        controllerIcon="icon-search-thin"
                        direction="top">
                        <div>
                          <SearchInput
                            className="mobile-search"
                            trigger="enter"
                            onSearch={this.handleSearch}
                            onChange={this.handleChange}
                            value={keywords}
                            onFocus={this.onSearchInputFocus}
                            onBlur={this.onSearchInputBlur}
                            placeholder={this.props.l10n.formatMessage('global.search.placeholder')} />
                        </div>
                      </PopUpMenu> : null
                  }
                </div>
              </HideAt>
              <ShowAt lgAndAbove>
                <div className="header--desktop__logo logo col-4">
                  {logo()}
                </div>
              </ShowAt>
              <ShowAt lgAndAbove>
                <UmbrellaPortalController hide>
                  {!isKiosk && (
                    <nav className="navi col-4">
                      <ul>
                        {this.renderMenuItems()}
                      </ul>
                    </nav>
                  )}
                </UmbrellaPortalController>
              </ShowAt>
              <ShowAt lgAndAbove>
                <div className="header--desktop__info col-4">
                  {!isKiosk && (
                    <div className="app-header-item item help">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://activesupport.force.com/usersupport">
                        <i className="icon-help-circle-outline item-icon" />
                      </a>
                    </div>
                  )}
                  {languages ? (
                    <LanguageSelector
                      languages={languages}
                      currentLocale={locale}
                      onChange={onChangeLanguage}
                      languageItemClass="app-header-item item"
                      iconItemClass="item-icon" />
                  ) : null}
                  <UmbrellaPortalController hide>
                    {!isKiosk && (
                      <Fragment>
                        <div className="cut-item-line item" />
                        {this.renderAccountInfo()}
                      </Fragment>
                    )}
                  </UmbrellaPortalController>
                </div>
              </ShowAt>
            </div>
          </div>
          <ShowAt lgAndAbove>
            {
              this.isHeaderInfoShow(path)
                ? <div className="header--desktop__infobar header row">
                  <div className="app-header-item item results-label">
                    <HeaderLabel path={path} />
                  </div>
                  {
                    path.match(/^\/(events|participants)\/(.*?)/) === null
                      ? <div className="app-header-item item results-search">
                        <GlobalSearch
                          onSearch={this.onSearch}
                          handleChange={this.handleChange}
                          keywordsValue={keywords} />
                      </div> : null
                  }
                </div> : null
            }
          </ShowAt>
        </div>
        <div className={classNames('app-header-space', { 'app-header-space-home': !this.isHeaderInfoShow(path) })} />
      </Fragment>
    );
  }
}

const container = connect(
  (state) => {
    return {
      user: getUser(state),
      availableLanguages: getAvailableLanguages(state),
      locale: getLocale(state),
      passportLink: getPassportLink(state),
      whiteLabelReady: isWhiteLabelReady(state),
      eventLogo: getWhiteLabelLogo(state),
      keywords: getKeywords(state),
      tabType: getTabType(state),
      portalPath: getPortalPath(state),
      hasPortalPath: hasUsedPortalPath(state),
      applicationName: getApplicationName(state),
      event: getEvent(state),
    };
  },
  (dispatch) => {
    return {
      onSignOutClick: () => dispatch(signOut()),
      onChangeLanguage: ({ value }) => dispatch(changeLanguage(value)),
      fetchSearchEvent:
        (keywords: string, offset: number, limit: number) => dispatch(fetchSearchEvent(keywords, offset, limit)),
      fetchSearchPerson:
        (keywords: string, offset: number, limit: number) => dispatch(fetchSearchPerson(keywords, offset, limit)),
      handleChange: (value: string) => dispatch(handleChange(value)),
      doSearch: () => dispatch(doSearch()),
    };
  },
  null,
  {
    pure: false,
  },
);

export default container(withRouter(smartSearch((injectL10n()(Header)))));
