// @flow

import { schema } from 'normalizr';
import type { Group } from 'type/type';

export const ENTITY_NAME = 'groups';

export default new schema.Entity(ENTITY_NAME, undefined, {
  processStrategy(g: Group) {
    return {
      ...g,
      id: g.id && g.id.toString(),
    };
  },
});
