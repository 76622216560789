// @flow

import type { PortalPath } from 'type/type';

const appendPortalPath = (portalPath: PortalPath, path: string) => {
  const { portalType, portalId } = portalPath || {};
  return portalType && portalId ? `/${portalType}/${portalId}${path}` : path;
};

export default appendPortalPath;
