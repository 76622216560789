// @flow

import { ActionsObservable } from 'redux-observable';
import ajax from 'services/ajax/ajax';
import Alert from 'react-aaui/lib/alert';
import { getMessage } from 'services/localize/localeProvider';

import { CLAIM, claimSuccess, claimFailed } from './duck';

export const claimResultEpics = (action$: ActionsObservable) => action$.ofType(CLAIM)
  .mergeMap((action) => {
    return ajax.put({
      url: `/results/participants/${action.participantId}?action=CLAIM`,
      headers: { accept: 'application/vnd.active.results-service.v1+json' },
      body: action.payload,
    }, {
      mapSuccess: (response) => {
        Alert.success({
          message: getMessage('global.claim.success'),
          inverse: true,
        });

        return claimSuccess(action.resultId, response);
      },
      mapFailure: () => claimFailed(action.resultId),
    });
  });
