// @flow

import { createSelector } from 'reselect';
import { fromJS, Map } from 'immutable';
import { getEventEntities } from 'modules/event/selector';
import { getPersonEntities } from 'modules/person/selector';
import {
  TAB_EVENT,
  TAB_PARTICIPANT,
} from 'services/query/query';

export const isLoadingSearchEvents = (state: Map<*, *>) => state.getIn([ 'searchPage', 'loadingSearchEvents' ]);

const getSearchEventIds = (state: Map<*, *>) => state.getIn([ 'searchPage', 'searchEventsList' ]);

export const getSearchEvents = createSelector(
  [ getEventEntities, getSearchEventIds ],
  (events, ids) => {
    const list = ids.filter(id => !!id).map(id => events.get(id));
    return fromJS(list);
  },
);

export const isLoadingSearchPersons = (state: Map<*, *>) => state.getIn([ 'searchPage', 'loadingSearchPersons' ]);

export const getTotalSearchPersons = (state: Map<*, *>) => state.getIn([ 'searchPage', 'totalSearchPersons' ]);

const getSearchPersonEpIds = (state: Map<*, *>) => state.getIn([ 'searchPage', 'searchPersonsList' ]);

export const getSearchPersons = createSelector(
  [ getPersonEntities, getSearchPersonEpIds ],
  (persons, ids) => {
    const list = ids.filter(id => !!id).map(id => persons.get(id));
    return fromJS(list);
  },
);

export const getKeywords = (state: Map<*, *>) => state.getIn([ 'searchPage', 'keywords' ]);

export const getTabType = (state: Map<*, *>) => state.getIn([ 'searchPage', 'currentSearchType' ]);

export const getTotalSearchEvents = (state: Map<*, *>) => state.getIn([ 'searchPage', 'totalSearchEvents' ]);

export const getTotalSearchNumber = createSelector(
  [ getTabType, getTotalSearchEvents, getTotalSearchPersons ],
  (type, eventsCount, personCount) => {
    switch (type) {
      case TAB_PARTICIPANT:
        return personCount;
      case TAB_EVENT:
        return eventsCount;
      default:
        return personCount + eventsCount;
    }
  },
);

export const shouldUpdateTotalRecords = (state: Map<*, *>) => state.getIn([ 'searchPage', 'updateTotalRecords' ]);

export const getParticipantPageNumber = (state: Map<*, *>) => state.getIn([ 'searchPage', 'currentPageNumberParticipants' ]);
export const getEventPageNumber = (state: Map<*, *>) => state.getIn([ 'searchPage', 'currentPageNumberEvents' ]);
