// @flow

import { schema } from 'normalizr';
import hashcode from 'hashcode';
import { NONE_ACTIVE_PERSON } from 'services/query/query';

export const ENTITY_NAME = 'persons';

export default new schema.Entity(ENTITY_NAME, undefined, {
  idAttribute: v => (v.enterprisePersonId || NONE_ACTIVE_PERSON.concat(hashcode.hashCode().value(v).toString())),
  processStrategy: v => ({
    ...v,
    enterprisePersonId: v.enterprisePersonId || NONE_ACTIVE_PERSON.concat(hashcode.hashCode().value(v).toString()),
  }),
});