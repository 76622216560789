// @flow

import { Map } from 'immutable';
import { mergeEntity } from '@active/awe-ui-core/lib/services/redux';
import ParticipantEntity from 'modules/participant/entity';
import { normalize } from 'normalizr';

import { ENTITY_NAME as RESULT } from './entity';

// Action type
export const CLAIM = 'results/claim';
export const CLAIM_SUCCESS = 'results/claimSuccess';
export const CLAIM_FAILED = 'results/claimFailed';

// Actions
export const claim = (enterprisePersonId: string, resultId: number, participantId: number) => ({
  type: CLAIM,
  payload: {
    person: {
      enterprisePersonId,
    },
  },
  resultId,
  participantId,
});

export const claimSuccess = (resultId: number, response: any) => ({
  type: CLAIM_SUCCESS,
  payload: normalize(response, ParticipantEntity),
  resultId,
});

export const claimFailed = (resultId: number) => ({
  type: CLAIM_FAILED,
  resultId,
});

export const initialState = new Map();

export const reducer = (state: Map<*, *> = initialState, action: any) => {
  switch (action.type) {
    case CLAIM:
      return state
        .setIn([ `${action.resultId}`, 'isClaiming' ], true);
    case CLAIM_SUCCESS:
    case CLAIM_FAILED:
      return state.setIn([
        `${action.resultId}`, 'isClaiming' ], false);
    default:
      return mergeEntity(state, action, RESULT);
  }
};
