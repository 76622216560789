// @flow

/**
  This Route cannot be used in react-router Switch,
  since the path attribute on the wrapper dom would disturb the implementation of Switch.
*/

import { Route as ReactRouterRoute } from 'react-router-dom';

import WithPortal from './WithPortal';

const Route = WithPortal('path')(ReactRouterRoute);

export default Route;
