// @flow

import * as React from 'react';
import connect from '@active/awe-ui-core/lib/hoc/connect';
import { hasUsedPortalPath } from 'modules/app/selector';

type Props = {
  children: React.Node,
  show: ?boolean,
  hide: ?boolean,
  hasPortalPath: boolean
}

export const NO_PARAM_ERROR = 'Param \'show\' or \'hide\' is required.';
export const SIMU_PARAM_ERROR = 'Param \'show\' and \'hide\' cannot be passed simultaneously.';

function validateParams(show, hide) {
  if (!show && !hide) {
    throw new Error(NO_PARAM_ERROR);
  }

  if (!!show && !!hide) {
    throw new Error(SIMU_PARAM_ERROR);
  }

  return true;
}

function UmbrellaPortalController({
  children, show, hide, hasPortalPath,
}: Props) {
  if (validateParams(show, hide)) {
    if (hasPortalPath) {
      return show ? children : null;
    }

    return hide ? children : null;
  }
}

const mapState = (state: Map<*, *>): Props => ({
  hasPortalPath: hasUsedPortalPath(state),
});

export default connect(mapState)(UmbrellaPortalController);
