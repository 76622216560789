import values from 'lodash/values';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/mergeMap';

import * as appEpics from './app/epics';
import * as userEpics from './user/epics';
import * as whiteLabelEpics from './whiteLabel/epics';
import * as claimResultEpics from './result/epics';

const epics$ = new ReplaySubject();
export const activate = epic => epics$.next(epic);
const activateEpics = epics => values(epics).forEach(activate);

export const rootEpic = (action$, store) => epics$.mergeMap(epic => epic(action$, store));

activateEpics(appEpics);
activateEpics(userEpics);
activateEpics(whiteLabelEpics);
activateEpics(claimResultEpics);
