// @flow

import { appendQuery } from 'services/query/query';

export const navToPassport = (passportLink: string, key: ?string, value: string) => {
  if (key) {
    window.location.assign(passportLink + encodeURIComponent(appendQuery(window.location.origin.concat('/manage-results'), key, value)));
  } else {
    window.location.assign(passportLink + encodeURIComponent(window.location.href));
  }
};
