// @flow

import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import type {
  MenuItem, L10n, Person, Language,
} from 'type/type';
import L10nMessage from 'react-aaui/lib/shared/L10nMessage';
import hasKey from 'lodash/has';
import classNames from 'classnames';
import RoleFilter from 'components/RoleFilter/RoleFilter';
import UmbrellaPortalController from 'components/UmbrellaPortalController/UmbrellaPortalController';

import LanguageSelectorList from '../LanguageSelectorList/LanguageSelectorList';
import './PopUpMenuContent.scss';

type Props = {
  isMenuPopupShown: boolean,
  MENU_ITEMS: MenuItem[],
  toggleMenuFlowOut: Function,
  user: Person | null,
  locale: string,
  onSignOutClick: Function,
  goToLogin: Function,
  l10n: L10n,
  languages: Language[],
  onChangeLanguage: Function,
  showLanguageOnly?: boolean,
}

type State = {
  showMenu: boolean
}

class PopUpMenuContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showMenu: true,
    };
  }

  renderMenuItems() {
    const { isMenuPopupShown, MENU_ITEMS, toggleMenuFlowOut } = this.props;

    return MENU_ITEMS.map((menuItem) => {
      const link = (
        <NavLink exact className="menu-item" key={menuItem.key} activeClassName="active" to={menuItem.url} onClick={() => isMenuPopupShown && toggleMenuFlowOut()}>
          {menuItem.label}
        </NavLink>
      );
      return menuItem.roles ? (
        <RoleFilter key={menuItem.key} requiredRoles={menuItem.roles}>{link}</RoleFilter>
      ) : link;
    });
  }

  renderMobileMenu(items: Array<*>) {
    return items.map((item) => {
      if (hasKey(item, 'url')) {
        return (
          <a
            key={item.key}
            href={item.url}
            className={classNames('menu-item', item.className)}
            rel="noopener noreferrer">
            {item.label}
          </a>);
      }

      return (
        <div
          key={item.key}
          className={classNames('menu-item', item.className)}
          onClick={item.onClick}>
          {item.label}
          { hasKey(item, 'icon') && <i className={item.icon} />}
        </div>);
    });
  }

  renderMobileAccount() {
    const {
      user, l10n, onSignOutClick, goToLogin,
    } = this.props;

    if (user && user.enterprisePersonId) {
      const SETTING_ITEMS_LOGIN = [
        { label: `${l10n.formatMessage('app.header.hi')}, ${user.firstName}`, key: 'user-name', className: 'menu-item-user-name' },
        {
          onClick: onSignOutClick, label: <L10nMessage id="app.header.sign.out" />, key: 'sign-out', className: 'menu-item-sign-out',
        },
      ];

      return this.renderMobileMenu(SETTING_ITEMS_LOGIN);
    }

    const SETTING_ITEMS_NOTLOGIN = [
      {
        onClick: goToLogin, label: <L10nMessage id="app.mobile.header.menu.sign.in" />, key: 'sign-in', className: 'menu-item-sign-in',
      },
      {
        onClick: goToLogin, label: <L10nMessage id="app.mobile.header.menu.sign.up" />, key: 'sign-up', className: 'menu-item-sign-up',
      },
    ];

    return this.renderMobileMenu(SETTING_ITEMS_NOTLOGIN);
  }

  renderMobileHelp() {
    const { locale, languages } = this.props;
    const currentLanguage = languages ? locale : null;

    const HELP_ITEMS = [
      { url: 'http://activesupport.force.com/usersupport', label: <L10nMessage id="app.mobile.header.menu.help" />, key: 'help' },
      {
        onClick: this.onClickLanguage, label: <L10nMessage id={currentLanguage} />, key: 'language', className: 'menu-item-language', icon: 'icon-angle-right-thin',
      },
    ];

    return this.renderMobileMenu(HELP_ITEMS);
  }

  renderMobileLanguageSelector() {
    const { locale, languages } = this.props;
    const currentLanguage = languages ? locale : null;

    return this.renderMobileMenu([{
      key: 'language',
      onClick: this.onClickLanguage,
      label: <L10nMessage id={currentLanguage} />,
      className: 'menu-item-language',
      icon: 'icon-angle-right-thin',
    }]);
  }

  handleItemClick = (value: string) => {
    const { onChangeLanguage, isMenuPopupShown, toggleMenuFlowOut } = this.props;

    onChangeLanguage(value);

    isMenuPopupShown && toggleMenuFlowOut();
  }

  onClickLanguage = () => {
    this.setState(prev => ({
      showMenu: !prev.showMenu,
    }));
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.isMenuPopupShown && nextProps.isMenuPopupShown) {
      this.setState({
        showMenu: true,
      });
    }
  }

  render() {
    const { showMenu } = this.state;
    const { locale, languages, showLanguageOnly } = this.props;
    const currentLanguage = languages ? locale : null;

    const languageProps = {
      languages,
      currentLanguage,
      handleItemClick: this.handleItemClick,
    };

    return (
      <div className="pop-up-menu-content">
        {showMenu ? (
          <Fragment>
            <UmbrellaPortalController hide>
              {!showLanguageOnly && (
                <Fragment>
                  <ul className="mobile-menu">
                    {this.renderMenuItems()}
                  </ul>
                  <div className="aside-divider" />
                  <ul className="mobile-menu">
                    {this.renderMobileAccount()}
                  </ul>
                  <div className="aside-divider" />
                </Fragment>
              )}
            </UmbrellaPortalController>
            <ul className="mobile-menu">
              {showLanguageOnly ? this.renderMobileLanguageSelector() : this.renderMobileHelp()}
            </ul>
          </Fragment>
        ) : (
          <Fragment>
            <div className="language-selector-list-title">
              <L10nMessage id="app.mobile.header.language.selector.list.title" />
            </div>
            <LanguageSelectorList {...languageProps} />
          </Fragment>
        )}
      </div>
    );
  }
}

export default PopUpMenuContent;
