// @flow

import { schema } from 'normalizr';

import route from '../route/entity';

export const ENTITY_NAME = 'events';

export default new schema.Entity(
  ENTITY_NAME, {
    routes: [ route ],
  }, {
    idAttribute: 'applicationName',
    processStrategy: event => ({
      ...event,
      applicationName: event.applicationName && event.applicationName.toString(),
    }),
  });
