// @flow

import React from 'react';
import injectL10n from 'react-aaui/lib/shared/injectL10n';
import type { L10n } from 'type/type';
import Form from 'react-aaui/lib/form/Form';
import Row from 'react-aaui/lib/grid/Row';
import Col from 'react-aaui/lib/grid/Col';
import TextInput from 'react-aaui/lib/form/TextInput';
import Button from 'react-aaui/lib/Button';
import './GlobalSearch.scss';

type Props = {
  l10n: L10n,
  onSearch: () => void,
  handleChange: (value: string) => void,
  placeholder: string,
  size: string,
  keywordsValue: string
}

export class GlobalSearch extends React.PureComponent<Props> {
  onSearch = () => {
    this.props.onSearch();
  }

  handleChange = (value: string) => {
    this.props.handleChange(value !== undefined ? value : this.props.keywordsValue);
  }

  render() {
    const {
      l10n, placeholder = 'global.search.placeholder', size = 'df', keywordsValue,
    } = this.props;
    const className = 'global-search-'.concat(size);
    return (
      <Form
        className={className}
        onChange={(value: Object) => this.handleChange(value.values.keywords)}
        onSubmit={this.onSearch}>
        <Row gutter={0}>
          <Col span={10} sm={11}>
            <TextInput
              placeholder={l10n.formatMessage(placeholder)}
              name="keywords"
              className="global-search-input"
              value={keywordsValue}
              autoComplete="off" />
          </Col>
          <Col span={2} sm={1} className="global-search-button__wrapper">
            <Button className="global-search-button btn btn-super">
              <i className="icon-search" />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default injectL10n()(GlobalSearch);
