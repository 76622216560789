// @flow
import { normalize } from 'normalizr';
import type { Action, NoPayloadAction } from 'type/type';
import { fromJS, Map } from 'immutable';

import Participant from 'modules/participant/entity';

export const ALL_SPORTS_TYPE = 'ALL';

// Action types
export const ON_MOUNT = 'results/myResults/ON_MOUNT';
export const ON_VIEW_ALL = 'results/myResults/ON_VIEW_ALL';
export const FETCH_PARTICIPANT_EVENT_RESULTS = 'results/myResults/FETCH_PARTICIPANT_EVENT_RESULTS';
export const FETCH_PARTICIPANT_EVENT_RESULTS_DONE = 'results/myResults/FETCH_PARTICIPANT_EVENT_RESULTS_DONE';
export const CHANGE_RESULTS_FILTER = 'results/myResults/CHANGE_RESULTS_FILTER';

// Action creators
export const onMount = (enterprisePersonId: number): Action => ({
  type: ON_MOUNT,
  payload: {
    enterprisePersonId,
  },
});

export const fetchParticipantEventResults = (enterprisePersonId: number): Action => ({
  type: FETCH_PARTICIPANT_EVENT_RESULTS,
  payload: {
    enterprisePersonId,
  },
});

export const fetchParticipantEventResultsDone = (participantEventResults: Participant[]): Action => ({
  type: FETCH_PARTICIPANT_EVENT_RESULTS_DONE,
  payload: normalize(participantEventResults, [ Participant ]),
});

export const changeResultsFilter = (filter: any): Action => ({
  type: CHANGE_RESULTS_FILTER,
  payload: filter,
});

export const onViewAll = (): NoPayloadAction => ({
  type: ON_VIEW_ALL,
});

export const initialState = fromJS({
  loadResults: false,
  type: ALL_SPORTS_TYPE,
  viewAll: false,
  participants: [],
});

// reducer
export const reducer = (state: Map<*, *> = initialState, action: Action) => {
  switch (action.type) {
    case ON_MOUNT:
      return initialState;
    case FETCH_PARTICIPANT_EVENT_RESULTS:
      return state.set('loadResults', true);
    case FETCH_PARTICIPANT_EVENT_RESULTS_DONE:
      return state.set('loadResults', false).set('participants', fromJS(action.payload.result));
    case CHANGE_RESULTS_FILTER:
      return state.set('type', action.payload).set('viewAll', false);
    case ON_VIEW_ALL:
      return state.set('viewAll', true);
    default:
      return state;
  }
};
