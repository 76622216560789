// @flow

import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { getEntities, getById, mapToEntities } from '../selector';

export const getRouteStages = createSelector(
  [ getEntities('routeStages'), getEntities('routeStagePoints') ],
  (routeStages: Map<string, *>, routeStagePoints: Map<string, *>) => routeStages.map(routeStage => routeStage.update('routeStagePoints', mapToEntities(routeStagePoints)),
  ),
);

export const getRouteStage = createSelector(
  [ getEntities('routeStages'), getEntities('routeStagePoints') ],
  (routeStages: Map<string, *>, routeStagePoints: Map<string, *>) => memoize(
    (id: number) => getById(routeStages, id).update('routeStagePoints', mapToEntities(routeStagePoints)),
  ),
);
