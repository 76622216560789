// @flow
import { schema } from 'normalizr';

export const ENTITY_NAME = 'groupRanks';

export default new schema.Entity(ENTITY_NAME, undefined, {
  idAttribute: groupRank => (groupRank.stageId
    ? `${groupRank.groupId}-${groupRank.stageId}-${groupRank.rank}`
    : `${groupRank.groupId}-FINAL-${groupRank.rank}`),
});
