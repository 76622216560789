// @flow

import { createSelector } from 'reselect';
import { getEntities, getById } from 'modules/selector';
import { Map } from 'immutable';

export const getPersonEntities = createSelector(
  [ getEntities('persons') ],
  (persons: Map<string, *>) => persons.map(person => person),
);

export const getPerson = (state: Map<string, *>, enterprisePersonId: string) => getById(getEntities('persons', state), enterprisePersonId);

export const getAge = createSelector(
  [ getPerson ],
  person => (person && person.get('age')),
);