// @flow
import React from 'react';
import L10nMessage from 'react-aaui/lib/shared/L10nMessage';

type Props = {
  path: string,
}
const HeaderLabel = ({ path }: Props) => {
  let headerLabel = 'app.header.resultsLabel';
  if (path.match(/^\/events\/(.*?)/) !== null) {
    headerLabel = 'app.header.eventLabel';
  } else if (path.match(/^\/(participants|my-results)\/(.*?)/) !== null) {
    headerLabel = 'app.header.participantLabel';
  } else if (path.match(/^\/my-results$/) !== null) {
    headerLabel = 'app.header.myResultsLabel';
  } else if (path.match(/^\/search/) !== null) {
    headerLabel = 'app.header.searchLabel';
  }
  return (
    <span>
      <L10nMessage id={headerLabel} />
    </span>
  );
};

export default HeaderLabel;
