import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'active.css/dist/css/active.css';
import 'react-aaui/dist/css/react-aaui.css';
import './scss/main.scss';
import App from './components/App/App';
import CookieButton from './components/App/CookieButton/CookieButton';
import configStore from './bootstrap/configStore';
import registerServiceWorker from './bootstrap/registerServiceWorker';
import L10nProviderContainer from './components/L10nProviderContainer/L10nProviderContainer';

const renderApp = () => {
  const store = configStore();
  ReactDOM.render(
    <Provider store={store}>
      <L10nProviderContainer>
        <Fragment>
          <App />
          <CookieButton />
        </Fragment>
      </L10nProviderContainer>
    </Provider>,
    document.getElementById('root'));
};

const loadFont = () => {
  if (document.body) {
    document.body.className = 'ProximaNova--loaded';
  }
};

loadFont();
renderApp();
registerServiceWorker();
