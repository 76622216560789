// @flow

import { Map } from 'immutable';
import { createSelector } from 'reselect';
import type { Event } from 'type/type';
import { getEntities, mapToEntities, getById } from 'modules/selector';
import { getEvents } from 'modules/event/selector';
import { getParticipantEntities } from 'modules/participant/selector';
import getFinalResult from 'services/getFinalResult/getFinalResult';
import compose from 'lodash/fp/compose';
import filter from 'lodash/fp/filter';
import sortBy from 'lodash/fp/sortBy';
import concat from 'lodash/fp/concat';
import flow from 'lodash/fp/flow';
import find from 'lodash/fp/find';
import propEq from 'lodash/fp/propEq';
import prop from 'lodash/fp/prop';

const getEventResult = (state: Map<*, *>) => state.getIn([ 'eventPage', 'eventResult' ]);
export const getEventLimit = (state: Map<*, *>) => state.getIn([ 'eventPage', 'viewLimit' ]);
export const getResultType = (state: Map<*, *>) => state.getIn([ 'eventPage', 'resultType' ]);
const searchedParticipantIds = (state: Map<*, *>) => state.getIn([ 'eventPage', 'searchedParticipants' ]);
export const getCourseId = (state: Map<*, *>) => state.getIn([ 'eventPage', 'courseId' ]);
export const getEventViewType = (state: Map<*, *>) => state.getIn([ 'eventPage', 'viewType' ]);

export const getApplicationName = (state: Map<*, *>) => state.getIn([ 'eventPage', 'applicationName' ]);
export const isLoadingEvent = (state: Map<string, *>) => state.getIn([ 'eventPage', 'loadingEvent' ]);
export const getDisplayCount = (state: Map<*, *>) => state.getIn([ 'eventPage', 'displayRecordsLength' ]);
export const getRaceId = (state: Map<*, *>) => state.getIn([ 'eventPage', 'raceId' ]);
export const getImportRoutes = (state: Map<*, *>) => state.getIn([ 'eventPage', 'importRoutes' ]);
export const getImportHistory = (state: Map<*, *>) => state.getIn([ 'eventPage', 'importHistory' ]);
export const getDistance = (state: Map<*, *>) => state.getIn([ 'eventPage', 'distance' ]);
export const getUploadId = (state: Map<*, *>) => state.getIn([ 'eventPage', 'uploadId' ]);
export const getUploadFileName = (state: Map<*, *>) => state.getIn([ 'eventPage', 'uploadFileName' ]);
export const getUploadFileValue = (state: Map<*, *>) => state.getIn([ 'eventPage', 'uploadFileValue' ]);
export const getIsImporting = (state: Map<*, *>) => state.getIn([ 'eventPage', 'isImporting' ]);
export const getIsUploading = (state: Map<*, *>) => state.getIn([ 'eventPage', 'isUploading' ]);

export const getEvent = createSelector(
  [ getEvents, getApplicationName ],
  (events: Map<string, Event>, applicationName: string) => events.get(applicationName),
);

export const getRoutes = createSelector(
  getEvent,
  (event: Map<*, *>) => event.get('routes'),
);

export const getResultRouteId = createSelector(
  [ getEvent, getCourseId, getEventViewType ],
  (event, courseId, viewType) => {
    if (courseId) return courseId;
    const { routes } = event.toJSON();
    const isVirtual = viewType === 'VIRTUAL';
    return flow(
      find(propEq('virtual', isVirtual)),
      prop('id'),
    )(routes);
  },
);

const IsResultUseChipTime = createSelector(
  [ getResultRouteId, getEntities('routes') ],
  (routeId, routes) => routes.getIn([ `${routeId}`, 'useChipTime' ]),
);

export const sortRouteGroup = (groupArray: Array<*, *> = []) => {
  const SORT_PROP = 'name';
  const OVERALL = /^OVERALL/i;
  const nonCaseSensitiveNameSort = originalArray => sortBy(
    item => item[SORT_PROP] && item[SORT_PROP].toLowerCase(),
  )(originalArray);
  const overAllRanks = compose(nonCaseSensitiveNameSort, filter(r => OVERALL.test(r[SORT_PROP])))(groupArray);
  const nonOverAllRanks = compose(sortBy(SORT_PROP), filter(r => !OVERALL.test(r[SORT_PROP])))(groupArray);

  return concat(overAllRanks)(nonOverAllRanks);
};

export const getGroupsByRoutes = createSelector(
  getRoutes,
  getResultRouteId,
  (routes, routeId) => routes.find(route => route.get('id') === routeId)
    .get('groups').filter(group => group.get('groupType') === 'DIVISION'),
);

export const getResultDivisionId = (state: Map<*, *>) => state.getIn([ 'eventPage', 'divisionId' ], getGroupsByRoutes(state).getIn([ 0, 'id' ]));

export const getSearchedParticipants = createSelector(
  [ getParticipantEntities, searchedParticipantIds, getDisplayCount ],
  (participants, ids, count) => {
    const persons = mapToEntities(participants)(ids).map((p) => {
      return p.get('person')
        .set('bib', p.get('bib'))
        .set('raceName', p.get('raceName'))
        .set('participantId', p.get('id'));
    });
    return persons.take(count);
  },
);
const TRANSITION = 'TRANSITION';
const getEventSplitStages = createSelector(
  [ getRoutes, getResultRouteId ],
  (routes, routeId) => {
    const currentRoute = routes.find(route => route.get('id') === routeId);
    const splitStages = currentRoute.get('stages');

    return splitStages.sort((itemA, itemB) => {
      if ((itemA.get('sequence') < itemB.get('sequence')) || (itemA.get('sequence') === itemB.get('sequence') && itemB.get('type') === TRANSITION)) {
        return -1;
      }
      return 1;
    });
  },
);

export const getEventResultList = createSelector(
  [ getParticipantEntities,
    getEntities('results'),
    getEntities('routeStages'),
    getEntities('routeStageResults'),
    getEventResult,
    getEventSplitStages,
    IsResultUseChipTime ],
  (participants, results, routeStages, routeStageResults, eventResult, eventSplitStages, useChipTime) => {
    const resultList = eventResult.map((id) => {
      const participant = getById(participants, id);
      const result = results.get(`${participant.get('finalResult')}`);
      const resultId = result && result.get('id');
      const eventStageResults = result.get('routeStageResults').map(routeStageResultId => routeStageResults.get(`${routeStageResultId}`));

      const routeStageResultList = eventSplitStages.map((stage) => {
        const eventStageResult = eventStageResults.find(eventStageResultItem => eventStageResultItem.get('routeStageId') === stage.get('id'));
        if (!eventStageResult) {
          return ({
            id: stage.get('id'),
            name: stage.get('name'),
          });
        }
        const routeStageResultId = eventStageResult.get('id');
        return ({
          id: routeStageResultId,
          name: stage.get('name'),
          finishTime: getFinalResult(useChipTime, eventStageResult),
        });
      }).toArray();

      return ({
        id,
        bib: participant.get('bib'),
        person: participant.get('person').toJS(),
        finishTime: getFinalResult(useChipTime, result),
        routeStageResultList,
        routeStageResultsSize: result.get('routeStageResults').size,
        resultId,
        participantNumber: participant.get('participantNumber'),
      });
    });
    return resultList;
  },
);

export const getParticipantSearchResult = (state: Map<*, *>) => state.getIn([ 'eventPage', 'participantSearchResult' ]);
export const isSearchingParticipantByNumber = (state: Map<*, *>) => state.getIn([ 'eventPage', 'loadingSearchParticipantByNumber' ]);
export const isSearchParticipantByNumberFailed = (state: Map<*, *>) => state.getIn([ 'eventPage', 'searchParticipantByNumberFailed' ]);
export const isSubmittingVirtualEventResult = (state: Map<*, *>) => state.getIn([ 'eventPage', 'loadingSumbitVirtialEventResult' ]);
export const isVirtualEventResultSubmitted = (state: Map<*, *>) => state.getIn([ 'eventPage', 'isVirtualEventResultSubmitted' ]);
