// @flow

import { combineReducers } from 'redux-immutable';
import { reducer as homePage } from 'scenes/Home/duck/duck';
import { reducer as eventPage } from 'scenes/Event/duck/duck';
import { reducer as participantPage } from 'scenes/Participant/duck/duck';
import { reducer as myResultsPage } from 'scenes/MyResults/duck/duck';
import { reducer as manageResultsPage } from 'scenes/ManageResults/duck/duck';
import { reducer as popup } from 'components/Popup/duck/duck';
import { reducer as searchPage } from 'scenes/SearchResults/duck/duck';
import { reducer as umbrellaHomePage } from 'scenes/UmbrellaHome/duck/duck';

import { reducer as app } from './app/duck';
import { reducer as user } from './user/duck';
import { reducer as entities } from './entitiesReducer';
import { reducer as whiteLabel } from './whiteLabel/duck';

export const rootReducer = combineReducers({
  app,
  entities,
  user,
  homePage,
  eventPage,
  participantPage,
  myResultsPage,
  manageResultsPage,
  popup,
  whiteLabel,
  searchPage,
  umbrellaHomePage,
});
