// @flow

import React, { Component } from 'react';
import connect from '@active/awe-ui-core/lib/hoc/connect';
import { getPortalPath } from 'modules/app/selector';
import curry from 'lodash/fp/curry';
import { PortalPath as PortalPathType } from 'type/type';
import { PORTAL_ROUTE } from 'services/router/enum';
import appendPortalPath from 'services/router/appendPortalPath';

type PortalPathProps = {
  portalPath: PortalPathType
}
type PropertyName = 'to' | 'path'

const WithPortal = curry((propertyName: PropertyName, WrappedComponent: React.Node, props: Object) => {
  class PortalPath extends Component<PortalPathProps> {
    generatePropertyValue() {
      const { portalPath } = this.props;
      const { portalType, portalId } = portalPath || {};
      const propertyValue = props[propertyName];
      const hasPortal = !!(portalType && portalId);

      switch (propertyName) {
        case 'to':
          return appendPortalPath(portalPath, propertyValue);
        case 'path':
          return hasPortal ? `${PORTAL_ROUTE}${propertyValue}` : propertyValue;
        default:
          return propertyValue;
      }
    }

    render() {
      const newProps = {
        ...props,
        [propertyName]: this.generatePropertyValue(),
      };

      return (
        <WrappedComponent {...newProps} />
      );
    }
  }
  const mapState = state => ({
    portalPath: getPortalPath(state),
  });
  const PortalPathContainer = connect(mapState)(PortalPath);

  return <PortalPathContainer {...props} />;
});

export default WithPortal;
