// @flow

import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { getEntities, mapToEntities } from 'modules/selector';

import { getRoutes } from '../route/selector';

export const getEventEntities = getEntities('events');

export const getEvents = createSelector(
  [ getEntities('events'), getRoutes ],
  (events: Map<*, *>, routes: Map<*, *>) => events.map(event => event.update('routes', mapToEntities(routes))),
);
