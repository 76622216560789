// @flow
import { fromJS, Map } from 'immutable';
import { normalize } from 'normalizr';
import EventEntity from 'modules/event/entity';
import PersonEntity from 'modules/person/entity';
import type { Action } from 'type/type';
import {
  TAB_EVENT,
  TAB_PARTICIPANT,
} from 'services/query/query';

// Action types
export const FETCH_SEARCH_EVENT = 'results/searchPage/FETCH_SEARCH_EVENT';
export const FETCH_SEARCH_EVENT_DONE = 'results/searchPage/FETCH_SEARCH_EVENT_DONE';
export const FETCH_SEARCH_PERSON = 'results/searchPage/FETCH_SEARCH_PERSON';
export const FETCH_SEARCH_PERSON_DONE = 'results/searchPage/FETCH_SEARCH_PERSON_DONE';
export const DO_SEARCH = 'results/searchPage/DO_SEARCH';
export const CHANGE_TAB = 'results/searchPage/CHANGE_TAB';
export const HANDLE_CHANGE = 'results/searchPage/HANDLE_CHANGE';
export const CHANGE_EVENT_PAGE = 'results/searchPage/CHANGE_EVENT_PAGE';
export const CHANGE_PARTICIPANT_PAGE = 'results/searchPage/CHANGE_PARTICIPANT_PAGE';
export const CHANGE_PAGE = 'results/searchPage/CHANGE_PAGE';
export const ON_MOUNT = 'results/searchPage/ON_MOUNT';

// Action creators

export const onMount = () => ({
  type: ON_MOUNT,
});

export const fetchSearchEvent = (keywords: string, offset: number, limit: number) => ({
  type: FETCH_SEARCH_EVENT,
  payload: {
    keywords,
    offset,
    limit,
  },
});

export const fetchSearchEventDone = (result: Object): Action => {
  const schema = { events: [ EventEntity ] };
  const data = normalize(result, schema);
  return {
    type: FETCH_SEARCH_EVENT_DONE,
    payload: data,
  };
};

export const fetchSearchPerson = (keywords: string, offset: number, limit: number) => ({
  type: FETCH_SEARCH_PERSON,
  payload: {
    keywords,
    offset,
    limit,
  },
});

export const fetchSearchPersonDone = (result: Object): Action => {
  const schema = { persons: [ PersonEntity ] };
  const data = normalize(result, schema);
  return {
    type: FETCH_SEARCH_PERSON_DONE,
    payload: data,
  };
};

export const doSearch = () => ({
  type: DO_SEARCH,
});

export const handleChange = (keywords: string) => ({
  type: HANDLE_CHANGE,
  payload: keywords,
});

export const changeTab = (tab: string) => ({
  type: CHANGE_TAB,
  payload: tab,
});

export const changePage = (index: number, tab: string, keywords: string) => ({
  type: CHANGE_PAGE,
  payload: {
    tab,
    index,
    keywords,
  },
});

export const initialState = fromJS({
  loadingSearchEvents: false,
  searchEventsList: [],
  loadingSearchPersons: false,
  searchPersonsList: [],
  currentSearchType: 'ALL',
  keywords: '',
  totalSearchEvents: 0,
  totalSearchPersons: 0,
  updateTotalRecords: false,
  currentPageNumberEvents: 1,
  currentPageNumberParticipants: 1,
});

// reducer
export const reducer = (state: Map<*, *> = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_SEARCH_PERSON:
      return state.set('loadingSearchPersons', true);
    case FETCH_SEARCH_PERSON_DONE:
      return state
        .set('loadingSearchPersons', false)
        .set('totalSearchPersons', action.payload.result.total)
        .set('searchPersonsList', action.payload.result.persons)
        .set('updateTotalRecords', true);
    case FETCH_SEARCH_EVENT:
      return state.set('loadingSearchEvents', true);
    case FETCH_SEARCH_EVENT_DONE:
      return state
        .set('loadingSearchEvents', false)
        .set('searchEventsList', action.payload.result.events)
        .set('totalSearchEvents', action.payload.result.total)
        .set('updateTotalRecords', true);
    case HANDLE_CHANGE:
      return state.set('keywords', action.payload)
        .set('updateTotalRecords', false);
    case DO_SEARCH:
      return state.set('updateTotalRecords', true)
        .set('currentPageNumberEvents', initialState.get('currentPageNumberEvents'))
        .set('currentPageNumberParticipants', initialState.get('currentPageNumberParticipants'));
    case CHANGE_PAGE: {
      const { tab, index } = action.payload;
      switch (tab) {
        case TAB_PARTICIPANT:
          return state.set('currentPageNumberParticipants', index);
        case TAB_EVENT:
          return state.set('currentPageNumberEvents', index);
        default:
          return state;
      }
    }
    case CHANGE_TAB:
      return state.set('currentSearchType', action.payload)
        .set('updateTotalRecords', true);
    default:
      return state;
  }
};
