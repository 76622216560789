// @flow
import React from 'react';

import './CookieButton.scss';

const CookieButton = () => {
  return (
    <div className="one-trust_cookie_label">
      <div className="one-trust_cookie_button">
        <div className="optanon-show-settings" />
      </div>
    </div>
  );
};

export default CookieButton;