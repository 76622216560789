// @flow

import { fromJS } from 'immutable';
import type { Action, NoPayloadAction } from 'type/type';

export type WhiteLabelConfig = {
  header: string,
  footer: string,
  eventLogo: string,
  theme: string
}

// Action Type
export const FETCH_WHITE_LABEL = 'results/whiteLabel/fetchWhiteLabel';
export const FETCH_WHITE_LABEL_DONE = 'results/whiteLabel/fetchWhiteLabelDone';
export const CLEAR_WHITE_LABEL = 'results/whiteLabel/clearWhiteLabel';

type Param = {
  applicationName?: string,
  participantId?: string
}

// Action Creators
export const fetchWhiteLabel = ({ applicationName, participantId }: Param): Action => ({
  type: FETCH_WHITE_LABEL,
  payload: {
    applicationName,
    participantId,
  },
});

export const fetchWhiteLabelDone = (whiteLabelConfig: ?WhiteLabelConfig): Action => ({
  type: FETCH_WHITE_LABEL_DONE,
  payload: {
    whiteLabelConfig,
  },
});

export const clearWhiteLabel = (): NoPayloadAction => ({
  type: CLEAR_WHITE_LABEL,
});

export const initialState = fromJS({
  fetchWhiteLabelDone: false,
  whiteLabel: null,
});

// Reducer
export const reducer = (state: Map<*, *> = initialState, action: Action): Map<*, *> => {
  switch (action.type) {
    case CLEAR_WHITE_LABEL:
      return initialState.set('fetchWhiteLabelDone', true);
    case FETCH_WHITE_LABEL:
      return initialState;
    case FETCH_WHITE_LABEL_DONE:
      return state.set('fetchWhiteLabelDone', true)
        .set('whiteLabel', fromJS(action.payload.whiteLabelConfig));
    default:
      return state;
  }
};
