// @flow

import Loadable from 'react-loadable';

const asyncComponent = (resolveComponent: () => Promise<*>) => {
  return Loadable({
    loader: resolveComponent,
    loading: () => null,
  });
};

export default asyncComponent;
