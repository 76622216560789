// @flow

import { schema } from 'normalizr';

export const ENTITY_NAME = 'splitResults';

function getId(splitResult) {
  return `${splitResult.participantId}_${splitResult.routeStagePointId}`;
}

export default new schema.Entity(ENTITY_NAME, undefined, {
  idAttribute: getId,
  processStrategy(sr) {
    return {
      ...sr,
      displayPace: sr.displayPace ? sr.displayPace.split(' ')[0] : null,
      distanceUnit: sr.distanceUnit || null,
      pace: sr.pace || null,
      result: sr.result || null,
      speed: sr.speed || null,
      splitTime: sr.splitTime || null,
      totalTime: sr.totalTime || null,
    };
  },
});
