// @flow

import { Map } from 'immutable';
import { createSelector } from 'reselect';
import moment from 'moment';

export const getUser = (state: Map<*, *>) => state.getIn([ 'user' ]);

export const getEnterprisePersonId = createSelector([ getUser ], (user: Map<*, *>) => {
  return user && user.get('enterprisePersonId');
});

export const isLoginUser = createSelector([ getUser ], (user: Map<*, *>) => !!user.get('enterprisePersonId'));

export const getUserEmail = createSelector([ getUser ], (user: Map<*, *>) => user.get('email'));

export const getAge = createSelector([ getUser ], (user: Map<*, *>) => moment().diff(user.get('dateOfBirth'), 'years'));

export const getUserName = createSelector([ getUser ], (user: Map<*, *>) => {
  const firstName = user.get('firstName');
  const lastName = user.get('lastName');
  return firstName ? `${firstName} ${lastName}` : '';
});

export const getUserLastName = createSelector([ getUser ], (user: Map<*, *>) => {
  return user ? user.get('lastName') : '';
});

export const getUserRoles = createSelector([ getUser ], (user: Map<*, *>) => user && user.get('roles'));
