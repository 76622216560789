// @flow

import { fromJS, Map } from 'immutable';
import { normalize } from 'normalizr';
import EventEntity from 'modules/event/entity';
import type {
  Action,
  Event,
  ParticipantSearchResult,
  SubmitVirtualEventResultPayload,
} from 'type/type';
import Participants from 'modules/participant/entity';
import union from 'lodash/union';
import prop from 'lodash/fp/prop';

export const NEW_RACE_ID = ' ';

// Action types

export const MOUNT = 'results/event/MOUNT';
export const MOUNTED = 'results/event/MOUNTED';
export const UNMOUNT = 'results/event/UNMOUNT';
export const FETCH_EVENT_DONE = 'results/event/FETCH_EVENT_DONE';
export const SEARCH_PARTICIPANT = 'results/event/home/SEARCH_PARTICIPANT';
export const SEARCH_PARTICIPANT_DONE = 'results/event/home/SEARCH_PARTICIPANT_DONE';
export const END_SEARCH = 'results/event/home/END_SEARCH';

export const CHANGE_COURSE_ID = 'results/event/home/CHANGE_COURSE_ID';
export const CHANGE_DIVISION_ID = 'results/event/home/CHANGE_DIVISION_ID';
export const CHANGE_VIEW_LIMIT = 'results/event/home/CHANGE_VIEW_LIMIT';
export const CHANGE_LEADER_BOARD_COURSE_ID = 'results/event/home/CHANGE_LEADER_BOARD_COURSE_ID';
export const CHANGE_LEADER_BOARD_DIVISION_ID = 'results/event/home/CHANGE_LEADER_BOARD_DIVISION_ID';
export const FETCH_EVENT_RESULT = 'results/event/home/FETCH_EVENT_RESULT';
export const FETCH_EVENT_RESULT_DONE = 'results/event/home/FETCH_EVENT_RESULT_DONE';
export const FETCH_LEADER_BOARD_RESULT = 'results/event/home/FETCH_LEADER_BOARD_RESULT';
export const FETCH_LEADER_BOARD_RESULT_DONE = 'results/event/home/FETCH_LEADER_BOARD_RESULT_DONE';
export const CLEAR_LEADER_BOARD_RESULT = 'results/event/home/CLEAR_LEADER_BOARD_RESULT';
export const HOME_PAGE_MOUNT = 'results/event/home/HOME_PAGE_MOUNT';
export const CHANGE_RESULT_TYPE = 'results/event/home/CHANGE_RESULT_TYPE';
export const SET_RESULT_LOADING = 'results/event/home/SET_RESULT_LOADING';
export const SEARCH_PARTICIPANT_BY_NUMBER = 'results/event/home/SEARCH_PARTICIPANT_BY_NUMBER';
export const SEARCH_PARTICIPANT_BY_NUMBER_DONE = 'results/event/home/SEARCH_PARTICIPANT_BY_NUMBER_DONE';
export const SEARCH_PARTICIPANT_BY_NUMBER_FAILED = 'results/event/home/SEARCH_PARTICIPANT_BY_NUMBER_FAILED';
export const SUBMIT_VIRTUAL_EVENT_RESULT = 'results/event/home/SUBMIT_VIRTUAL_EVENT_RESULT';
export const SUBMIT_VIRTUAL_EVENT_RESULT_DONE = 'results/event/home/SUBMIT_VIRTUAL_EVENT_RESULT_DONE';
export const SUBMIT_VIRTUAL_EVENT_RESULT_FAILED = 'results/event/home/SUBMIT_VIRTUAL_EVENT_RESULT_FAILED';

export const FETCH_IMPORT_ROUTES = 'results/event/FETCH_IMPORT_ROUTES';
export const FETCH_IMPORT_ROUTES_DONE = 'results/event/FETCH_IMPORT_ROUTES_DONE';
export const FETCH_IMPORT_HISTORY = 'results/event/FETCH_IMPORT_HISTORY';
export const FETCH_IMPORT_HISTORY_DONE = 'results/event/FETCH_IMPORT_HISTORY_DONE';
export const CLEAR_UPLOAD_FILE = 'results/event/CLEAR_UPLOAD_FILE';
export const UPLOAD_RESULT_FILE = 'results/event/UPLOAD_RESULT_FILE';
export const UPLOAD_RESULT_FILE_DONE = 'results/event/UPLOAD_RESULT_FILE_DONE';
export const IMPORT_RESULT = 'results/event/IMPORT_RESULT';
export const IMPORT_RESULT_DONE = 'results/event/IMPORT_RESULT_DONE';
export const UPDATE_DISTANCE = 'results/event/UPDATE_DISTANCE';
export const SELECT_RACE = 'results/event/SELECT_RACE';

export const CHANGE_EVENT_VIEW_TYPE = 'results/event/home/CHANGE_EVENT_VIEW_TYPE';

// Action creators

export const mount = (applicationName: string) => ({
  type: MOUNT,
  payload: {
    applicationName,
  },
});

export const unmount = () => ({
  type: UNMOUNT,
});

export const mounted = (event: Event) => ({
  type: MOUNTED,
  payload: normalize(event, EventEntity),
});

export const fetchEventDone = (event: Event) => ({
  type: FETCH_EVENT_DONE,
  payload: normalize(event, EventEntity),
});

export const searchParticipant = (value: string, applicationName: string, length: number) => ({
  type: SEARCH_PARTICIPANT,
  payload: {
    value,
    applicationName,
    offset: length,
  },
});

export const searchParticipantDone = (participants: Object): Action => ({
  type: SEARCH_PARTICIPANT_DONE,
  payload: participants,
});

export const changeCourseId = ({ courseId, divisionId }: {
  courseId: string,
  divisionId: string,
}) => ({
  type: CHANGE_COURSE_ID,
  payload: { courseId, divisionId },
});

export const changeDivisionId = (id: string) => ({
  type: CHANGE_DIVISION_ID,
  payload: { id },
});

export const changeLeaderBoardCourseId = ({ courseId, divisionId }: {
  courseId: string,
  divisionId: string,
}) => ({
  type: CHANGE_LEADER_BOARD_COURSE_ID,
  payload: { courseId, divisionId },
});

export const changeLeaderBoardDivisionId = (id: string) => ({
  type: CHANGE_LEADER_BOARD_DIVISION_ID,
  payload: { id },
});

export const changeViewLimit = (id: string) => ({
  type: CHANGE_VIEW_LIMIT,
  payload: { id },
});

export const fetchEventResult = ({
  courseId, divisionId, applicationName, offset, limit,
}: {
  courseId: string,
  divisionId: string,
  applicationName: string,
  offset: number,
  limit: number,
}) => ({
  type: FETCH_EVENT_RESULT,
  payload: ({
    courseId, divisionId, applicationName, offset, limit,
  }),
});

export const fetchEventResultDone = (events: [ Event ]): Action => ({
  type: FETCH_EVENT_RESULT_DONE,
  payload: normalize(events, { items: [ Participants ] }),
});

export const fetchLeaderBoardResult = ({
  courseId, divisionId, applicationName, hideLoading = false,
}: {
  courseId: string,
  divisionId: string,
  applicationName: string,
  hideLoading: boolean
}) => ({
  type: FETCH_LEADER_BOARD_RESULT,
  payload: ({
    courseId, divisionId, applicationName, hideLoading,
  }),
});

export const fetchLeaderBoardResultDone = (results: {}): Action => ({
  type: FETCH_LEADER_BOARD_RESULT_DONE,
  payload: results,
});

export const clearLeaderBoardResult = () => ({
  type: CLEAR_LEADER_BOARD_RESULT,
});

export const endSearch = () => ({
  type: END_SEARCH,
});

export const homePageMount = () => ({
  type: HOME_PAGE_MOUNT,
});

export const changeResultType = (resultType: string) => ({
  type: CHANGE_RESULT_TYPE,
  payload: { resultType },
});

export const fetchImportRoutes = (applicationName: string) => ({
  type: FETCH_IMPORT_ROUTES,
  payload: {
    applicationName,
  },
});

export const fetchImportRoutesDone = (importRoutes: []) => ({
  type: FETCH_IMPORT_ROUTES_DONE,
  payload: importRoutes,
});

export const fetchImportHistory = (applicationName: string) => ({
  type: FETCH_IMPORT_HISTORY,
  payload: {
    applicationName,
  },
});

export const fetchImportHistoryDone = (history: []) => ({
  type: FETCH_IMPORT_HISTORY_DONE,
  payload: history,
});

export const updateDistance = (distance: string) => ({
  type: UPDATE_DISTANCE,
  payload: distance,
});

export const selectRace = (raceId: string) => ({
  type: SELECT_RACE,
  payload: raceId,
});

export const uploadResultFile = (fileData: {}) => ({
  type: UPLOAD_RESULT_FILE,
  payload: fileData,
});

export const uploadResultFileDone = (uploadId: string) => ({
  type: UPLOAD_RESULT_FILE_DONE,
  payload: uploadId,
});

export const setResultLoading = (isLoading: boolean) => ({
  type: SET_RESULT_LOADING,
  payload: isLoading,
});

export const importResult = (applicationName: string, config: {}) => ({
  type: IMPORT_RESULT,
  payload: ({ applicationName, config }),
});

export const importResultDone = (applicationName: string) => ({
  type: IMPORT_RESULT_DONE,
  payload: {
    applicationName,
  },
});

export const clearUploadFile = () => ({
  type: CLEAR_UPLOAD_FILE,
  payload: {},
});

export const searchParticipantByNumber = (payload: {
  eventId: string,
  registrationNumber: string,
}) => ({
  payload,
  type: SEARCH_PARTICIPANT_BY_NUMBER,
});

export const searchParticipantByNumberDone = (participantSearchResult: ParticipantSearchResult): Action => ({
  type: SEARCH_PARTICIPANT_BY_NUMBER_DONE,
  payload: participantSearchResult,
});

export const searchParticipantByNumberFailed = (): Action => ({
  type: SEARCH_PARTICIPANT_BY_NUMBER_FAILED,
  payload: {},
});

export const submitVirtualEventResult = (payload: SubmitVirtualEventResultPayload) => ({
  payload,
  type: SUBMIT_VIRTUAL_EVENT_RESULT,
});

export const submitVirtualEventResultDone = (): Action => ({
  payload: {},
  type: SUBMIT_VIRTUAL_EVENT_RESULT_DONE,
});

export const submitVirtualEventResultFailed = (): Action => ({
  payload: {},
  type: SUBMIT_VIRTUAL_EVENT_RESULT_FAILED,
});

export const changeEventViewType = (payload: {
  viewType: string,
  init: boolean,
}) => ({
  type: CHANGE_EVENT_VIEW_TYPE,
  payload,
});

// Reducer

export const initialState = fromJS({
  keyword: '',
  loadingSearchedParticipants: false,
  loadingEvent: false,
  loadingEventResult: false,
  eventResult: [],
  viewLimit: '10',
  total: 0,
  pageSize: 10,
  displayRecordsLength: 0,
  searchedParticipants: [],
  leaderBoardResult: [],
  resultType: 'RESULT',
  importRoutes: [],
  importHistory: [],
  distance: '',
  uploadFileName: '',
  uploadFileValue: '',
  raceId: NEW_RACE_ID,
  uploadId: '',
  isImporting: false,
  isUploading: false,
  loadingSearchParticipantByNumber: false,
  searchParticipantByNumberFailed: false,
  participantSearchResult: null,
  loadingSumbitVirtialEventResult: false,
  isVirtualEventResultSubmitted: false,
  viewType: null,
});

export const reducer = (state: Map<string, *> = initialState, action: Action) => {
  switch (action.type) {
    case MOUNT:
      return state.set('loadingEvent', true);
    case MOUNTED:
      return state.set('loadingEventResult', true);
    case UNMOUNT:
      return state.set('viewLimit', '10')
        .delete('divisionId')
        .delete('courseId')
        .set('eventResult', [])
        .set('totalCount', 0)
        .set('leaderBoardResult', [])
        .set('applicationName', '')
        .set('participantSearchResult', null)
        .set('loadingSearchParticipantByNumber', false)
        .set('searchParticipantByNumberFailed', false)
        .set('loadingSumbitVirtialEventResult', false)
        .set('isVirtualEventResultSubmitted', false);
    case FETCH_EVENT_DONE:
      return state
        .set('applicationName', action.payload.result.toString())
        .set('loadingEvent', false)
        .set('viewLimit', '10')
        .delete('divisionId')
        .delete('courseId')
        .set('eventResult', [])
        .set('leaderBoardResult', []);
    case SEARCH_PARTICIPANT:
      if (action.payload.offset === 1) {
        return state.set('total', 0)
          .set('displayRecordsLength', 0)
          .set('searchedParticipants', initialState.get('searchedParticipants'))
          .set('keyword', action.payload.value)
          .set('loadingSearchedParticipants', true);
      }
      return state.set('keyword', action.payload.value)
        .set('loadingSearchedParticipants', true);
    case SEARCH_PARTICIPANT_DONE:
      return state.update('displayRecordsLength', (drl) => {
        let increaseLength = 0;
        if (action.payload.recordsLength > state.get('pageSize')) {
          increaseLength = action.payload.recordsLength;
        } else {
          increaseLength = action.payload.total - state.get('displayRecordsLength');
        }
        if (increaseLength > state.get('pageSize')) {
          increaseLength = state.get('pageSize');
        }
        return drl + increaseLength;
      })
        .update('searchedParticipants',
          l => fromJS(union(l.toArray(), action.payload.result)))
        .set('total', action.payload.total)
        .set('loadingSearchedParticipants', false);
    case FETCH_EVENT_RESULT:
      return state.set('loadingEventResult', true);
    case SET_RESULT_LOADING:
      return state.set('loadingEventResult', !!action.payload);
    case FETCH_EVENT_RESULT_DONE:
      return state.set('eventResult', state.get('eventResult').concat(prop('payload.result.items')(action)))
        .set('loadingEventResult', false)
        .set('totalCount', prop('payload.result.meta.totalCount')(action));
    case CHANGE_LEADER_BOARD_COURSE_ID:
      return state.set('courseId', action.payload.courseId)
        .set('divisionId', action.payload.divisionId);
    case CHANGE_LEADER_BOARD_DIVISION_ID:
      return state.set('divisionId', action.payload.id);
    case CHANGE_COURSE_ID:
      return state.set('courseId', action.payload.courseId)
        .set('divisionId', action.payload.divisionId)
        .set('eventResult', []).set('totalCount', 0);
    case CHANGE_DIVISION_ID:
      return state.set('divisionId', action.payload.id)
        .set('eventResult', []).set('totalCount', 0);
    case CHANGE_VIEW_LIMIT:
      return state.set('viewLimit', action.payload.id)
        .set('eventResult', []).set('totalCount', 0);
    case END_SEARCH:
      return state.set('keyword', '');
    case HOME_PAGE_MOUNT:
      return state.set('keyword', '');
    case CHANGE_RESULT_TYPE:
      return state.set('resultType', action.payload.resultType)
        .set('eventResult', []).set('totalCount', 0);
    case FETCH_LEADER_BOARD_RESULT:
      return state.set('loadingEventResult', !action.payload.hideLoading);
    case FETCH_LEADER_BOARD_RESULT_DONE:
      return state.set('leaderBoardResult', action.payload).set('loadingEventResult', false);
    case CLEAR_LEADER_BOARD_RESULT:
      return state.set('leaderBoardResult', []);
    case FETCH_IMPORT_ROUTES_DONE:
      return state.set('importRoutes', action.payload || []);
    case FETCH_IMPORT_HISTORY_DONE:
      return state.set('importHistory', action.payload);
    case UPLOAD_RESULT_FILE:
      return state.set('uploadFileName', action.payload.file.name)
        .set('uploadFileValue', action.payload.value)
        .set('isUploading', true);
    case CLEAR_UPLOAD_FILE:
      return state.set('uploadFileName', '')
        .set('uploadFileValue', '')
        .set('uploadId', '')
        .set('isImporting', false)
        .set('isUploading', false);
    case UPLOAD_RESULT_FILE_DONE:
      return state.set('uploadId', action.payload)
        .set('isUploading', false);
    case IMPORT_RESULT:
      return state.set('isImporting', true);
    case IMPORT_RESULT_DONE:
      return state.set('raceId', NEW_RACE_ID)
        .set('uploadFileName', '')
        .set('uploadFileValue', '')
        .set('uploadId', '')
        .set('isImporting', false);
    case UPDATE_DISTANCE:
      return state.set('distance', action.payload);
    case SELECT_RACE:
      return state.set('raceId', action.payload);
    case SEARCH_PARTICIPANT_BY_NUMBER:
      return state.set('loadingSearchParticipantByNumber', true);
    case SEARCH_PARTICIPANT_BY_NUMBER_DONE:
      return state.set('participantSearchResult', action.payload)
        .set('loadingSearchParticipantByNumber', false);
    case SEARCH_PARTICIPANT_BY_NUMBER_FAILED:
      return state.set('loadingSearchParticipantByNumber', false)
        .set('searchParticipantByNumberFailed', true);
    case SUBMIT_VIRTUAL_EVENT_RESULT:
      return state.set('loadingSumbitVirtialEventResult', true);
    case SUBMIT_VIRTUAL_EVENT_RESULT_DONE:
      return state.set('loadingSumbitVirtialEventResult', false)
        .set('isVirtualEventResultSubmitted', true);
    case SUBMIT_VIRTUAL_EVENT_RESULT_FAILED:
      return state.set('loadingSumbitVirtialEventResult', false);
    case CHANGE_EVENT_VIEW_TYPE:
      return state.set('viewType', action.payload.viewType)
        .set('participantSearchResult', null)
        .set('searchParticipantByNumberFailed', false)
        .set('isVirtualEventResultSubmitted', false)
        .delete('divisionId')
        .delete('courseId')
        .set('viewLimit', '10');
    default:
      return state;
  }
};
