// @flow

import { fromJS, Map } from 'immutable';
import type { Action } from 'type/type';
// Action type
export const SHOW_POPUP = 'results/app/SHOW_POPUP';
export const CLOSE_POPUP = 'results/app/CLOSE_POPUP';

// Action creators
export const showPopup = () => ({
  type: SHOW_POPUP,
});

export const closePopup = () => ({
  type: CLOSE_POPUP,
});

// Reducer
const initialState = fromJS({
  isShowPopup: false,
});

export const reducer = (state: Map<*, *> = initialState, action: Action) => {
  switch (action.type) {
    case SHOW_POPUP:
      return state.set('isShowPopup', true);
    case CLOSE_POPUP:
      return state.set('isShowPopup', false);
    default:
      return state;
  }
};
