// @flow

import { combineReducers } from 'redux-immutable';
import { mergeEntity } from '@active/awe-ui-core/lib/services/redux';

import { ENTITY_NAME as EVENT } from './event/entity';
import { ENTITY_NAME as GROUP } from './group/entity';
import { ENTITY_NAME as ROUTE } from './route/entity';
import { ENTITY_NAME as ROUTE_STAGE } from './routeStage/entity';
import { ENTITY_NAME as ROUTE_STAGE_POINT } from './routeStagePoint/entity';
import { ENTITY_NAME as RESULT } from './result/entity';
import { ENTITY_NAME as ROUTE_STAGE_RESULT } from './routeStageResult/entity';
import { ENTITY_NAME as PARTICIPANT } from './participant/entity';
import { ENTITY_NAME as GROUP_RANK } from './groupRank/entity';
import { ENTITY_NAME as PERSON } from './person/entity';
import { ENTITY_NAME as SPLIT_RESULT } from './splitResult/entity';
import { reducer as resultReducer } from './result/duck';

const simpleMergeReducer = entityName => (state, action) => mergeEntity(state, action, entityName);

export const reducer = combineReducers({
  [EVENT]: simpleMergeReducer(EVENT),
  [GROUP]: simpleMergeReducer(GROUP),
  [ROUTE]: simpleMergeReducer(ROUTE),
  [ROUTE_STAGE]: simpleMergeReducer(ROUTE_STAGE),
  [ROUTE_STAGE_POINT]: simpleMergeReducer(ROUTE_STAGE_POINT),
  [RESULT]: resultReducer,
  [ROUTE_STAGE_RESULT]: simpleMergeReducer(ROUTE_STAGE_RESULT),
  [PARTICIPANT]: simpleMergeReducer(PARTICIPANT),
  [GROUP_RANK]: simpleMergeReducer(GROUP_RANK),
  [PERSON]: simpleMergeReducer(PERSON),
  [SPLIT_RESULT]: simpleMergeReducer(SPLIT_RESULT),
});
