// @flow

import React from 'react';
import connect from '@active/awe-ui-core/lib/hoc/connect';
import DefaultFooter from '@active/awe-ui-core/lib/components/Footer';
import {
  isWhiteLabelReady,
  getWhiteLabelFooter,
} from 'modules/whiteLabel/selector';
import prop from 'lodash/fp/prop';
import { isKioskMode } from 'services/kiosk/kiosk';

type Props = {
  readyToShow: boolean,
  whiteLabelFooter: ?string
}

export const Footer = ({ readyToShow, whiteLabelFooter }: Props) => {
  const path = prop('location.pathname')(window);
  const isKiosk = isKioskMode(window);
  const hideFooter = isKiosk && path.match(/^\/(events|participants)\/(.*?)/);

  if (readyToShow && !hideFooter) {
    if (whiteLabelFooter) {
      return <div className="app__white-label-footer" dangerouslySetInnerHTML={{ __html: whiteLabelFooter }} /> // eslint-disable-line
    }

    return <DefaultFooter />;
  }

  return null;
};

const mapToState = (state: Map<*, *>): Props => ({
  readyToShow: isWhiteLabelReady(state),
  whiteLabelFooter: getWhiteLabelFooter(state),
});

export default connect(mapToState)(Footer);
