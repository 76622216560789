// @flow
import { fromJS, Map, List } from 'immutable';
import { normalize } from 'normalizr';
import type { Action, Event } from 'type/type';
import EventEntity from 'modules/event/entity';
import moment from 'moment';
import {
  curry, find, map, compose, concat, reduce, compact,
} from 'lodash/fp';
import { EventImage } from 'modules/event/emum';

const fieldsMap = fromJS({
  'Registration ID': [ 'Registration ID' ],
  Age: [ 'Age' ],
  'Distance unit': [ 'Distance unit' ],
  'First name': [ 'First name', 'First', 'FN', 'Given Name' ],
  'Last name': [ 'Last name', 'Last', 'LN', 'Surname' ],
  Bib: [ 'Bib', 'Bib No', 'Bib#', 'NO', 'NO.', 'Runner No', 'Race Number' ],
  'Finish result': [ 'Finish result', 'Finish Clock Time', 'Finish Time' ],
  'Gun result': [ 'Gun result', 'Gun Time', 'Gun' ],
  'Chip result': [ 'Chip result', 'Chip Time', 'Chip' ],
  Gender: [ 'Gender', 'Sex' ],
  City: [ 'City' ],
  'State/province/county': [ 'State/province/county', 'State', 'Province', 'County' ],
  Nationality: [ 'Nationality', 'Country', 'Nation' ],
  Team: [ 'Team', 'Team name', 'Club name', 'Club', 'Group', 'Group name' ],
  'Division name': [ 'Division name', 'Age Group', 'AG', 'Class', 'Group', 'Div', 'Category' ],
  'Rank division': [ 'Rank division', 'Class Ranking', 'Group Ranking', 'Division Ranking',
    'Div Ranking' ],
  'Rank overall': [ 'Overall', 'Place', 'Place overall', 'Overall ranking',
    'Overall rank', 'Rank overall', 'Overall ranking' ],
  'Rank gender': [ 'Rank gender', 'Gender Group Ranking' ],
  'Average pace': [ 'Average pace', 'AVG Pace', 'Overall Pace', 'Pace' ],
  'Average speed': [ 'Average speed', 'AVG Speed', 'Overall speed', 'Speed' ],
  'Disqualify flag': [ 'Disqualify flag', 'DQ Flag', 'DNF', 'DNS', 'DNF/DNS' ],
  'Swim time': [ 'Swim time' ],
  'Swim distance': [ 'Swim distance' ],
  'Swim pace': [ 'Swim pace' ],
  'Swim overall ranking': [ 'Swim overall ranking' ],
  'Swim gender ranking': [ 'Swim gender ranking' ],
  'Swim division ranking': [ 'Swim division ranking' ],
  'Bike time': [ 'Bike time' ],
  'Bike distance': [ 'Bike distance' ],
  'Bike pace': [ 'Bike pace' ],
  'Bike overall ranking': [ 'Bike overall ranking' ],
  'Bike gender ranking': [ 'Bike gender ranking' ],
  'Bike division ranking': [ 'Bike division ranking' ],
  'Run time': [ 'Run time' ],
  'Run distance': [ 'Run distance' ],
  'Run pace': [ 'Run pace' ],
  'Run overall ranking': [ 'Run overall ranking' ],
  'Run gender ranking': [ 'Run gender ranking' ],
  'Run division ranking': [ 'Run division ranking' ],
  'T1 time': [ 'T1 time' ],
  'T2 time': [ 'T2 time' ],
  'Certificate URL': [ 'Certificate URL', 'Certificate', 'Certificates', 'Certificates URL', 'Certificate URLs', 'Certificates URLs' ],
  'Photo URL': [ 'Photo URL', 'Photo', 'Photos', 'Photos URL', 'Photo URLs', 'Photos URLs' ],
  'Video URL': [ 'Video URL', 'Video', 'Videos', 'Videos URL', 'Video URLs', 'Videos URLs' ],
});

export const ON_MOUNT = 'results/manageResults/ON_MOUNT';
export const FETCH_EVENTS = 'results/manageResults/FETCH_EVENTS';
export const FETCH_EVENTS_DONE = 'results/manageResults/FETCH_EVENTS_DONE';
export const DELETE_EVENT = 'results/manageResults/DELETE_EVENT';
export const DELETE_EVENT_DONE = 'results/manageResults/DELETE_EVENT_DONE';
export const UPDATE_EVENT = 'results/manageResults/UPDATE_EVENT';
export const UPDATE_EVENT_DONE = 'results/manageResults/UPDATE_EVENT_DONE';
export const UPDATE_FILTER_TAG = 'results/manageResults/UPDATE_FILTER_TAG';
export const UPDATE_SEARCH_KEYWORD = 'results/manageResults/UPDATE_SEARCH_KEYWORD';
export const UPDATE_PAGE_NUMBER = 'results/manageResults/UPDATE_PAGE_NUMBER';
export const TOGGLE_EDIT = 'results/manageResults/TOGGLE_EDIT';
export const SAVE_EVENT = 'results/manageResults/SAVE_EVENT';
export const SAVE_EVENT_DONE = 'results/manageResults/SAVE_EVENT_DONE';
export const SAVE_EVENT_FAILED = 'results/manageResults/SAVE_EVENT_FAILED';
export const DO_UPLOAD_FILE = 'results/manageResults/DO_UPLOAD_FILE';
export const UPLOAD_LOGO_DONE = 'results/manageResults/UPLOAD_LOGO_DONE';
export const UPLOAD_HERO_IMAGE_DONE = 'results/manageResults/UPLOAD_HERO_IMAGE_DONE';
export const UPLOAD_FILE_FAILED = 'results/manageResults/UPLOAD_FILE_FAILED';
export const ON_EDIT_RESULT_MOUNT = 'results/manageResults/ON_EDIT_RESULT_MOUNT';
export const ON_EDIT_RESULT_MOUNT_DONE = 'results/manageResults/ON_EDIT_RESULT_MOUNT_DONE';
export const SORT_EVENTS = 'results/manageResults/SORT_EVENTS';
export const CHANGE_EVENT_INTO = 'results/manageResults/CHANGE_EVENT_INTO';
export const CLEAR_RESULT_DATA = 'results/manageResults/CLEAR_RESULT_DATA';
export const FETCH_EVENTS_HISTORY = 'results/manageResults/FETCH_EVENTS_HISTORY';
export const FETCH_EVENTS_HISTORY_DONE = 'results/manageResults/FETCH_EVENTS_HISTORY_DONE';
export const FETCH_EVENTS_ROUTES = 'results/manageResults/FETCH_EVENTS_ROUTES';
export const FETCH_EVENTS_ROUTES_DONE = 'results/manageResults/FETCH_EVENTS_ROUTES_DONE';
export const ADD_RESULT = 'results/manageResults/ADD_RESULT';
export const EDIT_RESULT = 'results/manageResults/EDIT_RESULT';
export const UPDATE_DISTANCE = 'results/manageResults/UPDATE_DISTANCE';
export const SELECT_SPORT_TYPE = 'results/manageResults/SELECT_SPORT_TYPE';
export const CLEAR_UPLOAD_FILE = 'results/manageResults/CLEAR_UPLOAD_FILE';
export const RESET_RESULT_EDITOR = 'results/manageResults/RESET_RESULT_EDITOR';
export const UPLOAD_RESULT_FILE = 'results/manageResults/UPLOAD_RESULT_FILE';
export const UPLOAD_RESULT_FILE_DONE = 'results/manageResults/UPLOAD_RESULT_FILE_DONE';
export const IMPORT_RESULT = 'results/manageResults/IMPORT_RESULT';
export const IMPORT_RESULT_DONE = 'results/manageResults/IMPORT_RESULT_DONE';
export const FETCH_COLUMN_MAPPINGS = 'results/manageResults/FETCH_COLUMN_MAPPINGS';
export const FETCH_COLUMN_MAPPINGS_DONE = 'results/manageResults/FETCH_COLUMN_MAPPINGS_DONE';
export const FETCH_COLUMN_MAPPING_FAILED = 'results/manageResults/FETCH_COLUMN_MAPPING_FAILED';
export const SELECT_MAPPING = 'results/manageResults/SELECT_MAPPING';
export const FETCH_TIMER_PAGE = 'results/manageResults/FETCH_TIMER_PAGE';
export const FETCH_TIMER_PAGE_DONE = 'results/manageResults/FETCH_TIMER_PAGE_DONE';
export const SAVE_PAGE = 'results/manageResults/SAVE_PAGE';
export const SAVE_PAGE_DONE = 'results/manageResults/SAVE_PAGE_DONE';
export const CHECK_PAGE_DUPLICATE = 'results/manageResults/CHECK_PAGE_DUPLICATE';
export const EDIT_TIMER_SLUG = 'results/manageResults/EDIT_TIMER_SLUG';
export const RESET_TIMER_SLUG = 'results/manageResults/RESET_TIMER_SLUG';
export const SET_TIMER_URL_ERROR_MESSAGE = 'results/manageResults/SET_TIMER_URL_ERROR_MESSAGE';
export const REMOVE_PAGE_EVENT = 'results/manageResults/REMOVE_PAGE_EVENT';
export const REMOVE_PAGE_EVENT_DONE = 'results/manageResults/REMOVE_PAGE_EVENT_DONE';
export const ADD_PAGE_EVENT = 'results/manageResults/ADD_PAGE_EVENT';
export const ADD_PAGE_EVENT_DONE = 'results/manageResults/ADD_PAGE_EVENT_DONE';
export const PAGING_EVENT_FAIL = 'results/manageResults/PAGING_EVENT_FAIL';
export const GET_TIMER_TOKEN = 'results/manageResults/GET_TIMER_TOKEN';
export const GET_TIMER_TOKEN_DONE = 'results/manageResults/GET_TIMER_TOKEN_DONE';
export const GET_TIMER_TOKEN_FAILED = 'results/manageResults/GET_TIMER_TOKEN_FAILED';
export const GET_ROUTE_RESULT_FILE_ID = 'results/manageResults/GET_ROUTE_RESULT_FILE_ID';
export const GET_ROUTE_RESULT_FILE_ID_FAILED = 'results/manageResults/GET_ROUTE_RESULT_FILE_ID_FAILED';
export const GET_ROUTE_RESULT_FILE_ID_DONE = 'results/manageResults/GET_ROUTE_RESULT_FILE_ID_DONE';
export const DOWNLOAD_ROUTE_RESULT = 'results/manageResults/DOWNLOAD_ROUTE_RESULT';
export const CHECK_GENERATE_ROUTE_RESULT_FILE_STATUS = 'results/manageResults/CHECK_GENERATE_ROUTE_RESULT_FILE_STATUS';
export const CHECK_ROUTE_RESULT_FILE_STATUS_DONE = 'results/manageResults/CHECK_ROUTE_RESULT_FILE_STATUS_DONE';
export const CHECK_ROUTE_RESULT_FILE_STATUS_FAILED = 'results/manageResults/CHECK_ROUTE_RESULT_FILE_STATUS_FAILED';
export const DISABLE_DOWNLOAD = 'results/manageResults/DISABLE_DOWNLOAD';

export const onMount = () => ({
  type: ON_MOUNT,
});

export const fetchEventsDone = (events: []) => ({
  type: FETCH_EVENTS_DONE,
  payload: normalize(events, [ EventEntity ]),
});

export const updateFilterTag = (tagName: string) => ({
  type: UPDATE_FILTER_TAG,
  payload: tagName,
});

export const updateSearchKeyword = (keyword: string) => ({
  type: UPDATE_SEARCH_KEYWORD,
  payload: keyword,
});

export const updatePageNumber = (number: number) => ({
  type: UPDATE_PAGE_NUMBER,
  payload: number,
});

export const fetchEvents = () => ({
  type: FETCH_EVENTS,
  payload: {},
});

export const toggleEdit = () => ({
  type: TOGGLE_EDIT,
});

export const saveEvent = (eventData: {}) => ({
  type: SAVE_EVENT,
  payload: eventData,
});

export const saveEventDone = (event: Event) => ({
  type: SAVE_EVENT_DONE,
  payload: normalize(event, EventEntity),
});

export const saveEventFailed = () => ({
  type: SAVE_EVENT_FAILED,
});

export const doUploadFile = (params: {}) => ({
  type: DO_UPLOAD_FILE,
  payload: params,
});

export const uploadLogoDone = (url: string) => ({
  type: UPLOAD_LOGO_DONE,
  payload: url,
});

export const uploadHeroImageDone = (response: {}) => ({
  type: UPLOAD_HERO_IMAGE_DONE,
  payload: response,
});

export const uploadFileFailed = () => ({
  type: UPLOAD_FILE_FAILED,
});

export const onEditResultMount = (applicationName: string) => ({
  type: ON_EDIT_RESULT_MOUNT,
  payload: applicationName,
});

export const onEditResultMountDone = (event: Event) => ({
  type: ON_EDIT_RESULT_MOUNT_DONE,
  payload: {
    ...normalize(event, EventEntity),
    event,
  },
});

export const deleteEvent = (applicationName: string) => ({
  type: DELETE_EVENT,
  payload: applicationName,
});

export const deleteEventDone = (applicationName: string) => ({
  type: DELETE_EVENT_DONE,
  payload: applicationName,
});

export const sortEvents = (config: {}) => ({
  type: SORT_EVENTS,
  payload: config,
});

export const changeEventInfo = (data: Object) => ({
  type: CHANGE_EVENT_INTO,
  payload: data,
});

export const clearResultData = () => ({
  type: CLEAR_RESULT_DATA,
  payload: {},
});

export const fetchImportRoutes = (applicationName: string) => ({
  type: FETCH_EVENTS_ROUTES,
  payload: {
    applicationName,
  },
});

export const fetchImportRoutesDone = (importRoutes: []) => ({
  type: FETCH_EVENTS_ROUTES_DONE,
  payload: importRoutes,
});

export const fetchImportHistory = (applicationName: string) => ({
  type: FETCH_EVENTS_HISTORY,
  payload: {
    applicationName,
  },
});

export const fetchImportHistoryDone = (history: []) => ({
  type: FETCH_EVENTS_HISTORY_DONE,
  payload: history,
});

export const updateDistance = (distance: string) => ({
  type: UPDATE_DISTANCE,
  payload: distance,
});

export const selectSportType = (sportType: string) => ({
  type: SELECT_SPORT_TYPE,
  payload: sportType,
});

export const uploadResultFile = (fileData: {}) => ({
  type: UPLOAD_RESULT_FILE,
  payload: fileData,
});

export const uploadResultFileDone = (uploadId: string, applicationName: string, sportType: string) => ({
  type: UPLOAD_RESULT_FILE_DONE,
  payload: { uploadId, applicationName, sportType },
});

export const selectMapping = (activeColumn: string, rowNumber: number) => ({
  type: SELECT_MAPPING,
  payload: { rowNumber, activeColumn },
});

export const importResult = (applicationName: string, config: {}) => ({
  type: IMPORT_RESULT,
  payload: ({ applicationName, config }),
});

export const importResultDone = (applicationName: string) => ({
  type: IMPORT_RESULT_DONE,
  payload: {
    applicationName,
  },
});

export const fetchColumnMappings = (applicationName: string, config: {}) => ({
  type: FETCH_COLUMN_MAPPINGS,
  payload: ({ applicationName, config }),
});

export const fetchColumnMappingsDone = (columnMappings: []) => ({
  type: FETCH_COLUMN_MAPPINGS_DONE,
  payload: { columnMappings },
});

export const fetchColumnMappingFailed = () => ({
  type: FETCH_COLUMN_MAPPING_FAILED,
  payload: { isValidFile: false },
});

export const clearUploadFile = () => ({
  type: CLEAR_UPLOAD_FILE,
});

export const resetResultEditor = () => ({
  type: RESET_RESULT_EDITOR,
});

export const addResult = () => ({
  type: ADD_RESULT,
});

export const editResult = (config: {}) => ({
  type: EDIT_RESULT,
  payload: config,
});

export const fetchTimerPage = () => ({
  type: FETCH_TIMER_PAGE,
});

export const fetchTimerPageDone = (page?: {}) => ({
  type: FETCH_TIMER_PAGE_DONE,
  payload: page || {},
});

export const savePage = (page: {}) => ({
  type: SAVE_PAGE,
  payload: page,
});

export const savePageDone = (page: {}) => ({
  type: SAVE_PAGE_DONE,
  payload: page,
});

export const checkPageDuplication = (page: {}) => ({
  type: CHECK_PAGE_DUPLICATE,
  payload: page,
});

export const editTimerSlug = (slug: string) => ({
  type: EDIT_TIMER_SLUG,
  payload: slug,
});

export const resetTimerSlug = () => ({
  type: RESET_TIMER_SLUG,
});

export const setTimerURLErrorMessage = (messageCode: string) => ({
  type: SET_TIMER_URL_ERROR_MESSAGE,
  payload: messageCode,
});

export const removePageEvent = (pageId: number, eventId: number) => ({
  type: REMOVE_PAGE_EVENT,
  payload: {
    pageId,
    eventId,
  },
});

export const addPageEvent = (pageId: number, eventId: number) => ({
  type: ADD_PAGE_EVENT,
  payload: {
    pageId,
    eventId,
  },
});

export const removePageEventDone = (page: Object, eventId: number) => ({
  type: REMOVE_PAGE_EVENT_DONE,
  payload: {
    page,
    eventId,
  },
});

export const addPageEventDone = (page: Object, eventId: number) => ({
  type: ADD_PAGE_EVENT_DONE,
  payload: {
    page,
    eventId,
  },
});

export const pagingEventFail = (eventId: number) => ({
  type: PAGING_EVENT_FAIL,
  payload: {
    eventId,
  },
});

export const getTimerToken = () => ({
  type: GET_TIMER_TOKEN,
});

export const getTimerTokenDone = () => ({
  type: GET_TIMER_TOKEN_DONE,
});

export const getTimerTokenFailed = () => ({
  type: GET_TIMER_TOKEN_FAILED,
});

export const getRouteResultFileId = (payload: Object): Action => ({
  payload,
  type: GET_ROUTE_RESULT_FILE_ID,
});

export const getRouteResultFileIdDone = (payload: string): Action => ({
  payload,
  type: GET_ROUTE_RESULT_FILE_ID_DONE,
});

export const getRouteResultFileIdFailed = (): Action => ({
  payload: {},
  type: GET_ROUTE_RESULT_FILE_ID_FAILED,
});

export const downloadRouteResult = (): Action => ({
  payload: {},
  type: DOWNLOAD_ROUTE_RESULT,
});

export const checkGenerateRouteResultFileStatus = (payload: string): Action => ({
  payload,
  type: CHECK_GENERATE_ROUTE_RESULT_FILE_STATUS,
});

export const checkGenerateRouteResultFileStatusDone = (payload: string): Action => ({
  payload,
  type: CHECK_ROUTE_RESULT_FILE_STATUS_DONE,
});

export const checkGenerateRouteResultFileStatusFailed = (): Action => ({
  payload: {},
  type: CHECK_ROUTE_RESULT_FILE_STATUS_FAILED,
});

export const disableDownload = (): Action => ({
  payload: {},
  type: DISABLE_DOWNLOAD,
});
// Reducer

export const initialState = fromJS({
  eventList: [],
  loadingEventList: false,
  filterTag: 'all',
  keyword: '',
  pageNumber: 1,
  isEdit: true,
  fieldsData: {
    name: '',
    address: {
      country: '',
      city: '',
      stateProvince: '',
    },
    startDate: moment().format(),
    measurementUnit: '',
    description: '',
    visibility: true,
    url: '',
    timeZone: 'UTC',
    deleted: false,
    enabledAD: false,
    externalApplicationId: '',
    externalId: '',
  },
  loadingSaveEvent: false,
  sortColumn: 'date',
  order: 'DESC',
  loadingUploadLogo: false,
  loadingUploadHeroImage: false,
  saveEventDone: false,
  importHistory: [],
  importRoutes: [],
  distance: '',
  uploadFileName: '',
  uploadFileValue: '',
  sportType: 'Running',
  uploadId: '',
  raceId: '',
  loadingCount: 0,
  isEditingResult: false,
  showResultPanel: false,
  eventId: undefined,
  columnMappings: {
    submittedColumnNames: [],
    sampleDatas: [],
    activeFormats: [],
  },
  isValidFile: false,
  mapped: [],
  isLoadingSaveMapped: false,
  mappingErrors: [],
  mappingCurrentRow: null,
  page: {},
  isLoadingSlug: false,
  isSavingSlug: false,
  isEditingSlug: false,
  errorMessage: '',
  timerSlug: '',
  pagingEventIds: [],
  generatingToken: false,
  tokenGenerated: false,
  isGeneratingRouteResult: false,
  isGenerateRouteResultDone: false,
  isGenerateRouteResultSuccess: false,
  isRouteResultDownloaded: false,
  isGetRouteResultFileIdSuccess: false,
  routeResultFileId: null,
  routeResultFileStatus: null,
  canDownload: true,
});

const compareColumn = (value: string, column: string): boolean => (new RegExp(`^${value.replace('_', ' ')}$`, 'i')).test(column);

const findColumn = (compare: ?Function, column: string): ?string => fieldsMap.get(column, List()).find(compare);

const matchOneColumn = (
  targetColumns: Array<string>,
  column: string,
): ?string => find(compose(curry(findColumn), curry(compareColumn))(column))(targetColumns);

const matchMultColumns = (orginalColumns: Array<string>,
  targetColumns: Array<string>): Array<string> => map(column => matchOneColumn(targetColumns, column))(orginalColumns);

const mapSubmittedColumnName = (activeFormats: Array<string>,
  submittedColumnNames: Array<string>): Array<string> => reduce((columns, column) => {
  const isExisted = curry(matchOneColumn)(columns)(column);
  const mappedColumn = curry(matchOneColumn)(activeFormats)(column);
  return isExisted ? concat(columns, [ '' ]) : concat(columns, [ mappedColumn || '' ]);
}, [])(submittedColumnNames);

export const autoMapping = ({ activeFormats, submittedColumnNames }: {
  activeFormats: Array<string>,
  submittedColumnNames: Array<string> },
): List<string> => compose(List, matchMultColumns)(
  mapSubmittedColumnName(activeFormats, submittedColumnNames),
  activeFormats,
);

const setMapped = (action: Action, state: Map<string>) => {
  const mapped = state.get('mapped');
  return mapped.set(action.payload.rowNumber, action.payload.activeColumn);
};

export const reducer = (state: Map<string, *> = initialState, action: Action) => {
  switch (action.type) {
    case ON_EDIT_RESULT_MOUNT:
    {
      const isCreate = window.location.pathname === '/manage-results/create';
      return state.set('applicationName', action.payload)
        .set('isEdit', isCreate)
        .set('saveEventDone', false)
        .set('fieldsData', initialState.get('fieldsData'))
        .set('loadingSaveEvent', initialState.get('loadingSaveEvent'))
        .set('loadingUploadLogo', action.payload ? true : initialState.get('loadingUploadLogo'))
        .set('loadingCount', state.get('loadingCount') + 1)
        .set('eventId', initialState.get('eventId'));
    }
    case ON_EDIT_RESULT_MOUNT_DONE:
      if (state.get('applicationName') !== undefined) {
        const e = action.payload.event;
        const fields = {
          name: e.name,
          address: e.address,
          startDate: e.startDate,
          measurementUnit: e.measurementUnit,
          description: e.description,
          visibility: e.visibility,
          timeZone: e.timeZone,
          url: e.url,
          logo: e.logo,
          heroImage: e.heroImage,
          heroImageThumbnail: e.heroImageThumbnail,
          deleted: e.deleted,
          enabledAD: e.enabledAD,
          externalApplicationId: e.externalApplicationId,
          externalId: e.externalId,
          isVirtual: e.isVirtual,
        };
        return state.set('fieldsData', fromJS(fields))
          .set('loadingCount', state.get('loadingCount') - 1)
          .set('eventId', e.id)
          .set('loadingUploadLogo', false)
          .set('loadingUploadHeroImage', false);
      }
      return state;
    case FETCH_EVENTS:
      return state.set('loadingEventList', true);
    case FETCH_EVENTS_DONE:
      return state.set('eventList', fromJS(action.payload.result)).set('loadingEventList', false);
    case UPDATE_FILTER_TAG:
      return state.set('filterTag', action.payload).set('pageNumber', 1);
    case UPDATE_SEARCH_KEYWORD:
      return state.set('keyword', action.payload).set('pageNumber', 1);
    case UPDATE_PAGE_NUMBER:
      return state.set('pageNumber', action.payload);
    case TOGGLE_EDIT:
      return state.set('isEdit', true);
    case SAVE_EVENT:
      return state.set('loadingSaveEvent', true);
    case SAVE_EVENT_DONE:
      return state.set('loadingSaveEvent', false)
        .set('saveEventDone', true)
        .set('applicationName', action.payload.result)
        .set('isEdit', false)
        .set('fieldsData', fromJS(action.payload.entities.events[action.payload.result]));
    case SAVE_EVENT_FAILED:
      return state.set('loadingSaveEvent', false);
    case DO_UPLOAD_FILE:
      return state.set('loadingUploadLogo', action.payload.type === EventImage.LOGO)
        .set('loadingUploadHeroImage', action.payload.type === EventImage.HERO);
    case UPLOAD_LOGO_DONE:
      return state.set('fieldsData', fromJS({
        ...state.get('fieldsData').toJS(),
        logo: action.payload,
      }))
        .set('loadingUploadLogo', false);
    case UPLOAD_HERO_IMAGE_DONE:
      return state.set('fieldsData', fromJS({
        ...state.get('fieldsData').toJS(),
        heroImage: action.payload.url,
        heroImageThumbnail: action.payload.thumbnailUrl,
      }))
        .set('loadingUploadHeroImage', false);
    case UPLOAD_FILE_FAILED:
      return state.set('loadingUploadLogo', false).set('loadingUploadHeroImage', false);
    case SORT_EVENTS:
      return state.set('sortColumn', action.payload.column).set('order', action.payload.order);
    case DELETE_EVENT:
      return state.set('loadingCount', 1);
    case DELETE_EVENT_DONE: {
      const eventListLength = state.get('eventList').size;
      const pageNumber = state.get('pageNumber');
      const isLastEvent = eventListLength === pageNumber * 10 - 9;
      return state.set('eventList', state.get('eventList').filter(name => name !== action.payload))
        .set('loadingCount', 0)
        .set('pageNumber', (isLastEvent && pageNumber > 1) ? pageNumber - 1 : pageNumber);
    }
    case CHANGE_EVENT_INTO:
      return state.set('fieldsData', fromJS(action.payload));
    case FETCH_EVENTS_HISTORY:
      return state.set('loadingCount', state.get('loadingCount') + 1);
    case FETCH_EVENTS_HISTORY_DONE:
      return state.set('importHistory', fromJS(action.payload || []))
        .set('loadingCount', state.get('loadingCount') - 1);
    case FETCH_EVENTS_ROUTES:
      return state.set('loadingCount', state.get('loadingCount') + 1);
    case FETCH_EVENTS_ROUTES_DONE:
      return state.set('importRoutes', fromJS(action.payload || []))
        .set('loadingCount', state.get('loadingCount') - 1);
    case CLEAR_RESULT_DATA:
      return state.set('importHistory', fromJS([]))
        .set('importRoutes', fromJS([]));
    case ADD_RESULT:
      return state.set('showResultPanel', true)
        .set('isEditingResult', false);
    case EDIT_RESULT:
      return state.set('showResultPanel', true)
        .set('isEditingResult', true)
        .set('raceId', action.payload.id)
        .set('sportType', action.payload.sportType)
        .set('distance', action.payload.distanceName);
    case UPLOAD_RESULT_FILE:
      return state.set('uploadFileName', action.payload.file.name)
        .set('uploadFileValue', action.payload.value)
        .set('loadingCount', 1);
    case CLEAR_UPLOAD_FILE:
      return state.set('uploadFileName', '')
        .set('uploadFileValue', '')
        .set('uploadId', '')
        .set('loadingCount', 0);
    case RESET_RESULT_EDITOR:
      return state.set('uploadFileName', '')
        .set('uploadFileValue', '')
        .set('uploadId', '')
        .set('loadingCount', 0)
        .set('showResultPanel', false)
        .set('isEditingResult', false)
        .set('distance', '')
        .set('sportType', 'Running')
        .set('raceId', '');
    case UPLOAD_RESULT_FILE_DONE:
      return state.set('uploadId', action.payload.uploadId)
        .set('loadingCount', 0);
    case IMPORT_RESULT:
      return state.set('loadingCount', 1)
        .set('isLoadingSaveMapped', true);
    case IMPORT_RESULT_DONE:
      return state.set('raceId', '')
        .set('loadingCount', 0)
        .set('showResultPanel', false)
        .set('isEditingResult', false)
        .set('isLoadingSaveMapped', false);
    case FETCH_COLUMN_MAPPINGS:
      return state.set('columnMappings',
        fromJS({
          submittedColumnNames: [],
          sampleDatas: [],
          activeFormats: [],
        }));
    case FETCH_COLUMN_MAPPINGS_DONE:
      return state.set('columnMappings', fromJS(action.payload.columnMappings))
        .set('mapped', autoMapping(action.payload.columnMappings))
        .set('isValidFile', true);
    case FETCH_COLUMN_MAPPING_FAILED:
      return state.set('isValidFile', action.payload.isValidFile);
    case SELECT_MAPPING:
      return state.set('mapped', setMapped(action, state))
        .set('mappingCurrentRow', action.payload.rowNumber);
    case UPDATE_DISTANCE:
      return state.set('distance', action.payload);
    case SELECT_SPORT_TYPE:
      return state.set('sportType', action.payload);
    case FETCH_TIMER_PAGE:
      return state.set('isLoadingSlug', true);
    case SAVE_PAGE:
    case CHECK_PAGE_DUPLICATE:
      return state.set('isSavingSlug', true);
    case FETCH_TIMER_PAGE_DONE:
      return state.set('isLoadingSlug', false)
        .set('isSavingSlug', false)
        .set('isEditingSlug', false)
        .set('page', fromJS(action.payload))
        .set('timerSlug', action.payload.slug);
    case SAVE_PAGE_DONE:
      return state.set('isSavingSlug', false)
        .set('isEditingSlug', false)
        .set('page', fromJS(action.payload))
        .set('timerSlug', action.payload.slug);
    case EDIT_TIMER_SLUG: {
      const errorMsg = (action.payload ? '' : 'react-aaui.validation.required');
      return state.set('isEditingSlug', true).set('timerSlug', action.payload).set('errorMessage', errorMsg);
    }
    case RESET_TIMER_SLUG: {
      const currentSlug = state.getIn([ 'page', 'slug' ]);
      return state.set('isEditingSlug', false).set('timerSlug', currentSlug).set('errorMessage', '');
    }
    case SET_TIMER_URL_ERROR_MESSAGE:
      return state.set('errorMessage', action.payload).set('isSavingSlug', false);
    case REMOVE_PAGE_EVENT:
    case ADD_PAGE_EVENT: {
      return state.set('pagingEventIds', fromJS(compose(compact, concat)(state.get('pagingEventIds').toJS(), action.payload.eventId)));
    }
    case REMOVE_PAGE_EVENT_DONE:
    case ADD_PAGE_EVENT_DONE:
      return state.set('page', fromJS(action.payload.page))
        .set('pagingEventIds', fromJS(state.get('pagingEventIds').filter(id => id !== action.payload.eventId)));
    case PAGING_EVENT_FAIL:
      return state.set('pagingEventIds', fromJS(state.get('pagingEventIds').filter(id => id !== action.payload.eventId)));
    case GET_TIMER_TOKEN:
      return state.set('generatingToken', true).set('tokenGenerated', false);
    case GET_TIMER_TOKEN_DONE:
      return state.set('generatingToken', false).set('tokenGenerated', true);
    case GET_TIMER_TOKEN_FAILED:
      return state.set('generatingToken', false).set('tokenGenerated', false);
    case GET_ROUTE_RESULT_FILE_ID:
      return state.set('isGeneratingRouteResult', true)
        .set('isRouteResultDownloaded', false)
        .set('isGetRouteResultFileIdSuccess', false)
        .set('isGenerateRouteResultDone', false)
        .set('canDownload', true);
    case GET_ROUTE_RESULT_FILE_ID_DONE:
      return state.set('routeResultFileId', action.payload.fileIdName)
        .set('isGetRouteResultFileIdSuccess', true);
    case GET_ROUTE_RESULT_FILE_ID_FAILED:
      return state.set('isGeneratingRouteResult', false);
    case CHECK_GENERATE_ROUTE_RESULT_FILE_STATUS:
      return state.set('isGenerateRouteResultDone', false)
        .set('isGetRouteResultFileIdSuccess', false);
    case CHECK_ROUTE_RESULT_FILE_STATUS_DONE:
      return state.set('routeResultFileStatus', action.payload.status)
        .set('isGenerateRouteResultSuccess', action.payload.status === 'FILE_STATUS_READY');
    case CHECK_ROUTE_RESULT_FILE_STATUS_FAILED:
      return state.set('isGeneratingRouteResult', false)
        .set('isGenerateRouteResultDone', true);
    case DOWNLOAD_ROUTE_RESULT:
      return state.set('isGenerateRouteResultDone', false)
        .set('isGenerateRouteResultSuccess', false)
        .set('isGetRouteResultFileIdSuccess', false)
        .set('isGeneratingRouteResult', false)
        .set('isRouteResultDownloaded', true);
    case DISABLE_DOWNLOAD:
      return state.set('isGeneratingRouteResult', false)
        .set('isGenerateRouteResultDone', false)
        .set('isGenerateRouteResultSuccess', false)
        .set('isRouteResultDownloaded', false)
        .set('isGetRouteResultFileIdSuccess', false)
        .set('routeResultFileId', null)
        .set('routeResultFileStatus', null)
        .set('canDownload', false);
    default:
      return state;
  }
};
