// @flow
import React from 'react';
import type { History } from 'type/type';

type Props = {
  history: History
}

export default ({ path }: { path: string }) => (WrappedComponent: Object) => {
  return class SmartNav extends React.Component<Props> {
    onNav = () => {
      if (this.props.history.location.pathname !== path) {
        this.props.history.push(path);
      }
    }

    render() {
      const newProps = {
        onNav: this.onNav,
      };
      return <WrappedComponent {...this.props} {...newProps} />;
    }
  };
};
