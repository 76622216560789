// @flow

import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { getEntities, mapToEntities, getById } from 'modules/selector';

import { getRouteStageResults } from '../routeStageResult/selector';

const DEFAULT_UNIT = 'KM';

export const getResultEntities = getEntities('results');

export const getResults = createSelector(
  [ getResultEntities, getRouteStageResults ],
  (
    results: Map<string, *>,
    routeStageResults: Map<string, *>,
  ) => results.map(
    // use final result's distanceUnit, calculating distance unit inside results service
    result => result.update('routeStageResults', mapToEntities(routeStageResults))
      .set(
        'distanceUnit',
        result.get('distanceUnit')
        || DEFAULT_UNIT,
      ),
  ),
);

export const getClaimingState = (state: Map<*, *>, id: number) => {
  const result = getById(getEntities('results', state), id);

  return result ? result.get('isClaiming') : false;
};
