// @flow

import { ActionsObservable } from 'redux-observable';
import ajax from 'services/ajax/ajax';
import 'rxjs/add/operator/mergeMap';
import { SIGN_OUT, cleanLoginUser } from './duck';

export const signOutEpic = (action$: ActionsObservable) => {
  return action$
    .ofType(SIGN_OUT)
    .mergeMap(() => ajax.del({
      url: '/user/authcredentials',
      headers: {
        accept: 'application/vnd.active.results-api.v1+json',
      },
    }, { mapSuccess: () => cleanLoginUser() }));
};
