// @flow

import curry from 'lodash/curry';
import identity from 'lodash/identity';
import { Map } from 'immutable';
import Maybe, { maybe } from '@active/awe-ui-core/lib/fp/Maybe';

export const getEntities = curry((entityName, state) => maybe(new Map(), identity, Maybe.of(state).map(s => s.getIn([ 'entities', entityName ]))));

export const getEntityTables = (state: Map<string, *>) => state.get('entities');

export const mapToEntities = curry((entities: Map<*, *>, ids) => ids && ids.map(id => entities.get(id.toString())));

export const getById = curry((entities, id) => entities.get((id || '').toString()));
