// @flow

import { normalize } from 'normalizr';
import type { Action, Event } from 'type/type';
import { fromJS, Map } from 'immutable';

import EventEntity from 'modules/event/entity';

// Action types

export const ON_MOUNT = 'results/home/ON_MOUNT';
export const FETCH_RECENT_EVENT = 'results/home/FETCH_RECENT_EVENT';
export const FETCH_RECENT_EVENT_DONE = 'results/home/FETCH_RECENT_EVENT_DONE';

// Action creators

export const onMount = () => ({
  type: ON_MOUNT,
});

export const fetchRecentEvent = () => ({
  type: FETCH_RECENT_EVENT,
});

export const fetchRecentEventDone = (events: Event[]): Action => ({
  type: FETCH_RECENT_EVENT_DONE,
  payload: normalize(events, [ EventEntity ]),
});

// Reducer

export const initialState = fromJS({
  loadingRecentEvents: false,
  recentEventsList: [],
});

export const reducer = (state: Map<*, *> = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_RECENT_EVENT:
      return state.set('loadingRecentEvents', true);
    case FETCH_RECENT_EVENT_DONE:
      return state.set('loadingRecentEvents', false)
        .set('recentEventsList', action.payload.result);
    default:
      return state;
  }
};
