export const PORTAL_PATH = '/:portalType?/:portalId?';
export const HOME_PATH = '/';
export const PORTAL_HOME_PATH = `${PORTAL_PATH}${HOME_PATH}`;
export const EVENT_PATH = '/events/:applicationName';
export const EVENT_RESUBMIT_RESULT_PATH = '/events/:applicationName/submitResults';
export const PORTAL_EVENT_PATH = `${PORTAL_PATH}${EVENT_PATH}`;
export const EVENT_PARTICIPANTS_PATH = '/events/:applicationName/participants';
export const PORTAL_EVENT_PARTICIPANTS_PATH = `${PORTAL_PATH}${EVENT_PARTICIPANTS_PATH}`;
export const PARTICIPANTS_PATH = '/participants/:id';
export const PORTAL_PARTICIPANTS_PATH = `${PORTAL_PATH}${PARTICIPANTS_PATH}`;
export const MY_RESULTS_PATH = '/my-results/:enterprisePersonId?';
export const SEARCH_PATH = '/search';
export const MANAGE_RESULTS_PATH = '/manage-results';
export const IMPORT_PATH = '/events/:applicationName/import';
export const PORTAL_TYPES = [ 'a', 't' ];
export const PORTAL_ROUTE = '/:portalType(a|t)/:portalId';
