// @flow

import React from 'react';
import Spinner from '@active/awe-ui-core/lib/components/Spinner';

import './PageSpinner.scss';

const PageSpinner = () => (
  <div className="page-spinner">
    <Spinner />
  </div>
);

export default PageSpinner;
