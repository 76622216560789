// @flow

import { normalize } from 'normalizr';
import type { Action } from 'type/type';
import { fromJS, Map } from 'immutable';

import EventEntity from 'modules/event/entity';

// Action types

export const ON_UMBRELLA_MOUNT = 'results/umbrellaHome/ON_UMBRELLA_MOUNT';
export const FETCH_UMBRELLA_EVENT = 'results/umbrellaHome/FETCH_UMBRELLA_EVENT';
export const FETCH_UMBRELLA_EVENT_DONE = 'results/umbrellaHome/FETCH_UMBRELLA_EVENT_DONE';
export const CHANGE_PAGE = 'results/umbrellaHome/CHANGE_PAGE';
export const DO_SEARCH = 'results/umbrellaHome/DO_SEARCH';
export const HANDLE_CHANGE = 'results/umbrellaHome/HANDLE_CHANGE';
export const END_SEARCH = 'results/umbrellaHome/END_SEARCH';

// Action creators

export const onMount = (portalType: string, portalId: string) => ({
  type: ON_UMBRELLA_MOUNT,
  payload: {
    portalType,
    portalId,
  },
});

export const doSearch = () => ({
  type: DO_SEARCH,
});

export const endSearch = () => ({
  type: END_SEARCH,
});

export const handleChange = (keywords: string) => ({
  type: HANDLE_CHANGE,
  payload: keywords,
});

export const fetchUmbrellaEvent = (keywords: string, offset: number, //
  limit: number, portalType: string, portalId: string) => ({
  type: FETCH_UMBRELLA_EVENT,
  payload: {
    keywords,
    offset,
    limit,
    portalType,
    portalId,
  },
});

export const fetchUmbrellaEventDone = (result: Object): Action => {
  const schema = { events: [ EventEntity ] };
  const data = normalize(result, schema);

  return {
    type: FETCH_UMBRELLA_EVENT_DONE,
    payload: data,
  };
};

export const changePage = (latestKeywords: string, index: number, portalType: string, portalId: string) => ({
  type: CHANGE_PAGE,
  payload: {
    latestKeywords,
    index,
    portalType,
    portalId,
  },
});

// Reducer

export const initialState = fromJS({
  isFirstEntry: true,
  loadingUmbrellaEvents: true,
  umbrellaEventList: [],
  totalUmbrellaEvents: 0,
  currentPageNumberEvents: 1,
  keywords: '',
  latestKeywords: '',
});

export const reducer = (state: Map<*, *> = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_UMBRELLA_EVENT:
      return state.set('loadingUmbrellaEvents', true);
    case FETCH_UMBRELLA_EVENT_DONE:
      return state
        .set('loadingUmbrellaEvents', false)
        .set('umbrellaEventList', action.payload.result.events)
        .set('totalUmbrellaEvents', action.payload.result.total)
        .set('isFirstEntry', false);
    case CHANGE_PAGE: {
      const { index } = action.payload;
      return state.set('currentPageNumberEvents', index);
    }
    case HANDLE_CHANGE:
      return state.set('keywords', action.payload);
    case DO_SEARCH:
      return state
        .set('currentPageNumberEvents', initialState.get('currentPageNumberEvents'))
        .set('latestKeywords', state.get('keywords'));
    case END_SEARCH:
      return state.set('keywords', '')
        .set('latestKeywords', '');
    default:
      return state;
  }
};
