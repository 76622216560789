// @flow

import flow from 'lodash/fp/flow';
import keys from 'lodash/fp/keys';
import toLower from 'lodash/fp/toLower';
import find from 'lodash/fp/find';
import eq from 'lodash/fp/eq';
import type { Query } from 'type/type';

export const OFFSET = 1;
export const PERSON_LIMIT = 40;
export const EVENT_LIMIT = 20;
export const ALL_PERSON_LIMIT = 20;
export const ALL_EVENT_LIMIT = 10;
export const TAB_ALL = 'ALL';
export const TAB_PARTICIPANT = 'PARTICIPANT';
export const TAB_EVENT = 'EVENT';
export const NONE_ACTIVE_PERSON = 'NAP_';

export const transferDoubleQuote = (value: string) => {
  return value.replace(/\"/g, '\"\"') // eslint-disable-line
};

export const buildQueryExpression = (keyword: string) => {
  const encodedKeyword = transferDoubleQuote(keyword);
  return encodeURIComponent(`fn:"${encodedKeyword}" ln:"${encodedKeyword}" bib:"${encodedKeyword}"`);
};

export const buildQueryPersonExpression = (keyword: string) => {
  const encodedKeyword = transferDoubleQuote(keyword.trim());
  return encodeURIComponent(`fnp:"${encodedKeyword}" lnp:"${encodedKeyword}"`);
};

export const buildSearchEventQueryExpression = (keyword: string) => {
  const encodedKeyword = transferDoubleQuote(keyword.trim());
  return encodeURIComponent(`en:"${encodedKeyword}"`);
};

export const appendQuery = (uri: string, key: string, value: string) => {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }
  return `${uri}${separator}${key}=${value}`;
};

export const getKeyFromQuery = (
  caseSensitive: boolean = false,
) => ({ key, query }: {
  key: string,
  query: Query,
}) => {
  let keyFound: ?string;
  const queryKeys = keys(query);
  if (caseSensitive) {
    keyFound = find(eq(key))(queryKeys);
  } else {
    keyFound = find(flow(
      toLower,
      eq(toLower(key)),
    ))(queryKeys);
  }

  return keyFound || '';
};
