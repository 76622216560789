// @flow
import { fromJS } from 'immutable';

const getFinalResult = (useChipTime: ?boolean, resultData: Object) => {
  if (resultData) {
    const result = fromJS(resultData);
    const tmpResult = useChipTime ? result.get('chipTimeResult') : result.get('gunTimeResult');
    return result.get('finalResult') ? result.get('finalResult') : tmpResult;
  }

  return undefined;
};

export default getFinalResult;
