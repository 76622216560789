// @flow

import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { getEntities, mapToEntities } from 'modules/selector';

import { getRouteStages } from '../routeStage/selector';

export const getRouteEntities = getEntities('routes');

export const getRoutes = createSelector(
  [ getRouteEntities, getRouteStages, getEntities('groups') ],
  (routes: Map<string, *>, routeStages: Map<string, *>, groups: Map<string, *>) => routes.map(route => route
    .update('stages', mapToEntities(routeStages))
    .update('groups', mapToEntities(groups)),
  ),
);
