// @flow

import { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import ajax from 'services/ajax/ajax';
import { storeLanguage } from 'services/userPrefer/language';
import {
  APP_ERROR,
  CHANGE_LANGUAGE,
  fetchAppSettingsFulFilled,
  LAUNCH,
} from './duck';
import { fetchUserFulfilled } from '../user/duck';
import { handleError } from './errorHandling/handleError';
import { UserRole } from '../user/enum';

export const errorsEpic = (actions$: ActionsObservable) => actions$.ofType(APP_ERROR)
  .map(action => handleError(action.payload.httpCode,
    action.payload.message))
  .filter(action => action);

export const launchEpic = (action$: ActionsObservable) => {
  return action$.ofType(LAUNCH).mergeMap(() => ajax.get({
    url: '/app/settings',
    headers: {
      accept: 'application/vnd.active.results-api.v1+json',
    },
  }, { mapSuccess: appSettings => fetchAppSettingsFulFilled(appSettings) }));
};

const getUserWithRole = () => ajax.get({
  url: '/user',
  headers: { accept: 'application/vnd.active.results-api.v1+json' },
}, { mapSuccess: user => fetchUserFulfilled(user) });

const updateUserWithRole = () => ajax.post({
  url: '/user',
  headers: { accept: 'application/vnd.active.results-api.v1+json' },
  body: [ UserRole.TIMER ],
}, { mapSuccess: () => window.location.assign(window.location.href.replace(new RegExp('TIMER=.*?(&|$)'), '')) });

export const fetchUserEpic = (action$: ActionsObservable) => {
  return action$.ofType(LAUNCH).mergeMap(window.location.href.indexOf('TIMER=') > -1 ? updateUserWithRole : getUserWithRole);
};

export const changeLanguageEpic = (action$: ActionsObservable) => {
  return action$.ofType(CHANGE_LANGUAGE)
    .do(action => storeLanguage(action.payload.language))
    .ignoreElements();
};
