// @flow

import * as React from 'react';
import Modal from 'react-aaui/lib/modal/Modal';
import { bindActionCreators } from 'redux';
import connect from '@active/awe-ui-core/lib/hoc/connect';
import { showPopup, closePopup } from './duck/duck';
import './Popup.scss';

type Option = {
  component: React.Node,
  title: string,
  className: string,
}

type Props = {
  show: Function,
  onClose: Function,
  isShow: boolean
}

let children: React.Node;
let instance: React.Component<Props>;
let title: string;
let className: string;

export class Popup extends React.Component<Props> {
  componentDidMount() {
    instance = this;
  }

  shouldComponentUpdate({ isShow }: { isShow: boolean }) {
    return isShow !== this.props.isShow;
  }

  close = () => {
    this.props.onClose();
  }

  render() {
    const { isShow } = this.props;

    return (
      isShow
        ? <div className="popup">
          <Modal title={title} shown={isShow} onClose={this.close} className={className}>
            {children}
          </Modal>
        </div>
        : null
    );
  }
}

const mapDispatch = dispatch => bindActionCreators({
  show: showPopup,
  onClose: closePopup,
}, dispatch);

const mapState = (state) => {
  return {
    isShow: state.getIn([ 'popup', 'isShowPopup' ]),
  };
};

const container = connect(mapState, mapDispatch)(Popup);

container.show = (option: Option) => {
  children = option.component;
  title = option.title;
  className = option.className;
  if (instance) {
    instance.props.show();
  }
};

export default container;
