// @flow

import { fromJS, Map } from 'immutable';
import type { Action, User } from 'type/type';
import omit from 'lodash/omit';

export const SIGN_OUT = 'results/user/SIGN_OUT';
export const CLEAN_LOGIN_USER = 'results/user/CLEAN_LOGIN_USER';
export const FETCH_USER_FULFILLED = 'results/user/FETCH_USER_FULFILLED';

export const omitPostalCode = (user: User) => {
  if (user && user.address) {
    return {
      ...user,
      address: omit(user.address, [ 'postalCode' ]),
    };
  }

  return user;
};

export const signOut = () => ({
  type: SIGN_OUT,
});

export const cleanLoginUser = () => ({
  type: CLEAN_LOGIN_USER,
});

export const fetchUserFulfilled = (user: User) => ({
  type: FETCH_USER_FULFILLED,
  payload: omitPostalCode(user),
});

const initialState = new Map();

export const reducer = (state: Map<*, *> = initialState, action: Action) => {
  switch (action.type) {
    case FETCH_USER_FULFILLED: {
      return fromJS({
        ...action.payload,
      });
    }
    case CLEAN_LOGIN_USER: {
      return initialState;
    }
    default:
      return state;
  }
};
