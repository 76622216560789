// @flow

import { ActionsObservable } from 'redux-observable';
import url from '@active/awe-ui-core/lib/services/url';
import ajax from 'services/ajax/ajax';
import type { Action } from 'type/type';
import { MOUNT as EVENT_MOUNT } from 'scenes/Event/duck/duck';
import { MOUNT as PARTICIPANT_MOUNT } from 'scenes/Participant/duck/duck';
import { ON_MOUNT as HOME_MOUNT } from 'scenes/Home/duck/duck';
import { ON_MOUNT as MY_RESULTS_MOUNT } from 'scenes/MyResults/duck/duck';
import { ON_MOUNT as SEARCH_MOUNT } from 'scenes/SearchResults/duck/duck';
import {
  ON_MOUNT as MANAGE_RESULT_MOUNT,
  ON_EDIT_RESULT_MOUNT as MANAGE_RESULT_EDIT_MOUNT,
} from 'scenes/ManageResults/duck/duck';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import {
  fetchWhiteLabel,
  fetchWhiteLabelDone,
  clearWhiteLabel,
  FETCH_WHITE_LABEL,
} from './duck';

export const clearWhiteLabelEpic = (action$: ActionsObservable) => {
  return action$
    .filter(({ type }: Action) => ([
      HOME_MOUNT,
      MY_RESULTS_MOUNT,
      SEARCH_MOUNT,
      MANAGE_RESULT_MOUNT,
      MANAGE_RESULT_EDIT_MOUNT,
    ].indexOf(type) !== -1))
    .mapTo(clearWhiteLabel());
};

export const fetchEventWhiteLabelEpic = (action$: ActionsObservable) => {
  return action$
    .ofType(EVENT_MOUNT)
    .map(({ payload: { applicationName } }: Action) => {
      if (applicationName) {
        return fetchWhiteLabel({ applicationName });
      }

      return clearWhiteLabel();
    });
};

export const fetchParticipantWhiteLabelEpic = (action$: ActionsObservable) => {
  return action$
    .ofType(PARTICIPANT_MOUNT)
    .map(({ payload: { participantId } }: Action) => fetchWhiteLabel({ participantId }));
};

export const fetchWhiteLabelEpic = (action$: ActionsObservable) => {
  return action$
    .ofType(FETCH_WHITE_LABEL)
    .mergeMap((action: Action) => {
      const { applicationName, participantId } = action.payload;

      return ajax.get(
        url('/results/whitelabel')
          .appendParam('appName', applicationName && encodeURIComponent(applicationName))
          .appendParam('pid', participantId)
          .value(), {
          mapSuccess: fetchWhiteLabelDone,
          mapFailure: () => fetchWhiteLabelDone(null),
        },
      );
    });
};
