// @flow

import { Map } from 'immutable';
import { createSelector } from 'reselect';

export const getWhiteLabelConfig = (state: Map<string, *>): Map<string, *> => state.get('whiteLabel');

export const getWhiteLabel = createSelector(
  [ getWhiteLabelConfig ],
  (whiteLabelConfig: Map<string, *>): ?Map<string, *> => whiteLabelConfig.get('whiteLabel'),
);

export const isWhiteLabelReady = createSelector(
  [ getWhiteLabelConfig ],
  (whiteLabelConfig: Map<string, *>): boolean => whiteLabelConfig.get('fetchWhiteLabelDone'),
);

export const getWhiteLabelLogo = createSelector(
  [ getWhiteLabel ],
  (whiteLabel: ?Map<string, *>): ?string => whiteLabel && whiteLabel.get('eventLogo'),
);

export const getWhiteLabelHeader = createSelector(
  [ getWhiteLabel ],
  (whiteLabel: ?Map<string, *>): ?string => whiteLabel && whiteLabel.get('header'),
);

export const getWhiteLabelFooter = createSelector(
  [ getWhiteLabel ],
  (whiteLabel: ?Map<string, *>): ?string => whiteLabel && whiteLabel.get('footer'),
);

export const getWhiteLabelCSS = createSelector(
  [ getWhiteLabel ],
  (whiteLabel: ?Map<string, *>): ?string => whiteLabel && whiteLabel.get('theme'),
);
