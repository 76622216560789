// @flow

import { schema } from 'normalizr';
import RouteStagePointEntity from '../routeStagePoint/entity';

export const ENTITY_NAME = 'routeStages';

export default new schema.Entity(ENTITY_NAME, {
  routeStagePoints: [ RouteStagePointEntity ],
});
