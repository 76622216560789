// @flow

import { schema } from 'normalizr';
import groupRank from '../groupRank/entity';
import result from '../result/entity';
import splitResult from '../splitResult/entity';
import route from '../route/entity';
import event from '../event/entity';
import person from '../person/entity';

export const ENTITY_NAME = 'participants';

export default new schema.Entity(ENTITY_NAME, {
  finalResult: result,
  participatedEvent: event,
  participatedRoute: route,
  groupRanks: [ groupRank ],
  splitResults: [ splitResult ],
  person,
});
