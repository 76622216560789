// @flow

import React from 'react';
import connect from '@active/awe-ui-core/lib/hoc/connect';
import {
  isWhiteLabelReady,
  getWhiteLabelCSS,
} from 'modules/whiteLabel/selector';

type Props = {
  theme: ?string
}

export const WhiteLabelCSS = ({ theme }: Props) => {
  return theme ? <style>{theme}</style> : null;
};

const mapToState = (state: Map<*, *>) => ({
  theme: isWhiteLabelReady(state) && getWhiteLabelCSS(state),
});

export default connect(mapToState)(WhiteLabelCSS);
