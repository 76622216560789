// @flow

import * as React from 'react';
import connect from '@active/awe-ui-core/lib/hoc/connect';
import { Map } from 'immutable';
import intersection from 'lodash/intersection';
import { getUserRoles } from 'modules/user/selector';
import type { UserRole as UserRoleType } from 'type/type';

type Props = {
  userRoles: (?UserRoleType)[],
  requiredRoles: (UserRoleType)[],
  blockedRoles: (UserRoleType)[],
  children: React.Node,
  alternative: React.Node,
}

const RoleFilter = ({
  userRoles, requiredRoles, blockedRoles, children, alternative = null,
}: Props) => {
  if (intersection(requiredRoles, blockedRoles).length > 0) {
    throw new Error('Should not have interaction between requiredRoles and blockedRoles');
  }

  const matchRequiredRole = requiredRoles ? intersection(userRoles, requiredRoles).length > 0 : true;
  const matchBlockedRole = blockedRoles ? intersection(userRoles, blockedRoles).length === 0 : true;
  return matchRequiredRole && matchBlockedRole ? children : alternative;
};

const mapState = (state: Map<*, *>) => ({
  userRoles: getUserRoles(state),
});

const RoleFilterContainer = connect(mapState)(RoleFilter);

export default RoleFilterContainer;
