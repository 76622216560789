// @flow

import connect from '@active/awe-ui-core/lib/hoc/connect';
import L10nProvider from 'react-aaui/lib/shared/L10nProvider';
import { getLocale } from 'modules/app/selector';
import { loadMessages } from 'services/localize/localeProvider';

const L10nProviderContainer = connect(
  (state) => {
    return {
      locale: getLocale(state),
      resolveMessage: loadMessages,
    };
  },
)(L10nProvider);

export default L10nProviderContainer;
