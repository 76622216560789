// @flow

import localStorageProvider from 'active-ui-core/lib/userAgent/localStorageProvider';
import { getQueryParam } from 'active-ui-core/lib/userAgent/location';
import { getBrowserLocale } from 'active-ui-core/lib/userAgent/userLocale';

const LANGUAGE_KEY = 'language';

const getStoredLanguage = () => localStorageProvider(storage => storage.getItem(LANGUAGE_KEY));
const getUrlLanguage = () => getQueryParam('locale');

export const getUserLanguage = () => (getUrlLanguage() || getStoredLanguage() || getBrowserLocale());

export const storeLanguage = (lang: string) => localStorageProvider(storage => storage.setItem(LANGUAGE_KEY, lang));
