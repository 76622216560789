import React, { Component } from 'react';
import { BrowserRouter, Route, matchPath } from 'react-router-dom';
import PortalRoute from 'hoc/portalPath/Route';
import { bindActionCreators } from 'redux';
import { getUserLanguage } from 'services/userPrefer/language';
import connect from '@active/awe-ui-core/lib/hoc/connect';
import { launch } from 'modules/app/duck';
import asyncComponent from 'hoc/asyncComponent/asyncComponent';
import { isAppReady } from 'modules/app/selector';

import Maybe, { maybe } from '@active/awe-ui-core/lib/fp/Maybe';
import compose from 'lodash/fp/compose';
import prop from 'lodash/fp/prop';
import includes from 'lodash/includes';
import {
  HOME_PATH,
  PORTAL_HOME_PATH,
  EVENT_PATH,
  PORTAL_EVENT_PATH,
  PORTAL_EVENT_PARTICIPANTS_PATH,
  PARTICIPANTS_PATH,
  PORTAL_PARTICIPANTS_PATH,
  MY_RESULTS_PATH,
  SEARCH_PATH,
  MANAGE_RESULTS_PATH,
  PORTAL_TYPES,
} from 'services/router/enum';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import WhiteLabelCSS from './WhiteLabelCSS/WhiteLabelCSS';
import Popup from '../Popup/Popup';
import PageSpinner from '../PageSpinner/PageSpinner';
import './App.scss';

/* Pages */
const Home = asyncComponent(() => import(/* webpackChunkName: "home" */ 'scenes/Home/Home'));
const UmbrellaHome = asyncComponent(() => import(/* webpackChunkName: "umbrellaHome" */ 'scenes/UmbrellaHome/UmbrellaHome'));
const Event = asyncComponent(() => import(/* webpackChunkName: "event" */ 'scenes/Event/Event'));
const Participant = asyncComponent(() => import(/* webpackChunkName: "participant" */ 'scenes/Participant/Participant'));
const MyResults = asyncComponent(() => import(/* webpackChunkName: 'myResults' */ 'scenes/MyResults/MyResultsWrapper'));
const SearchResults = asyncComponent(() => import(/* webpackChunkName: 'searchResults' */ 'scenes/SearchResults/SearchResults'));
const ManageResults = asyncComponent(() => import(/* webpackChunkName: "manageResults" */ 'scenes/ManageResults/ManageResults'));

export class App extends Component {
  componentDidMount() {
    this.props.launch(getUserLanguage(), this.getPortalPath());
  }

  getPortalPath() {
    const pathName = window.location.pathname;
    const match = path => matchPath(pathName, { path, exact: true });

    return [ PORTAL_HOME_PATH, PORTAL_EVENT_PATH, PORTAL_EVENT_PARTICIPANTS_PATH,
      PORTAL_PARTICIPANTS_PATH ].reduce((portal, path) => {
      const portalType = portal.portalType || maybe('', compose(prop('portalType'), prop('params'), match), Maybe.of(path));
      const portalId = portal.portalId || maybe('', compose(prop('portalId'), prop('params'), match), Maybe.of(path));
      if (includes(PORTAL_TYPES, portalType)) {
        return {
          portalType,
          portalId: portalId || '',
        };
      }

      return {
        portalType: '',
        portalId: '',
      };
    }, {});
  }

  render() {
    const portalPath = this.getPortalPath();
    const hasPortalPath = !!(portalPath.portalType && portalPath.portalId);

    return this.props.isAppReady ? (
      <BrowserRouter>
        <div className="app">
          <Header />
          <div className="app__content">
            <PortalRoute exact path={HOME_PATH} component={hasPortalPath ? UmbrellaHome : Home} />
            <PortalRoute path={EVENT_PATH} component={Event} />
            <PortalRoute path={PARTICIPANTS_PATH} component={Participant} />
            <Route path={MY_RESULTS_PATH} component={MyResults} />
            <Route path={SEARCH_PATH} component={SearchResults} />
            <Route path={MANAGE_RESULTS_PATH} component={ManageResults} />
          </div>
          <Popup />
          <Footer />
          <WhiteLabelCSS />
        </div>
      </BrowserRouter>
    ) : <PageSpinner />;
  }
}

const mapState = state => ({
  isAppReady: isAppReady(state),
});
const mapDispatch = dispatch => bindActionCreators({ launch }, dispatch);

export default connect(mapState, mapDispatch)(App);
