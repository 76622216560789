// @flow

import { Map } from 'immutable';
import Maybe, { maybe } from '@active/awe-ui-core/lib/fp/Maybe';
import { createSelector } from 'reselect';

const getPassportSettings = (state: Map<*, *>) => state.getIn([ 'app', 'appSettings', 'passportSettings' ]);

export const getLocale = (state: Map<*, *>) => state.getIn([ 'app', 'locale' ]);

export const isFetchUserDone = (state: Map<*, *>) => state.getIn([ 'app', 'fetchUserDone' ]);

export const getAvailableLanguages = (state: Map<*, *>) => state.getIn([ 'app', 'availableLanguages' ]);

export const getPassportLink = createSelector(
  [ getLocale, getPassportSettings ],
  (locale: string, passportSettings: Map<*, *>) => maybe(
    '',
    c => `${c.get('activePassportBaseUrl')}/oauth2/authorize?client_id=${c.get(
      'activePassportClientId',
    )}&response_type=code&locale=${locale}&redirect_uri=`,
    Maybe.of(passportSettings),
  ),
);

export const getFacebookAppId = (state: Map<*, *>) => state.getIn([ 'app', 'appSettings', 'facebookAppId' ]);

export const getStaticContentServiceUrl = (state: Map<*, *>) => state.getIn([ 'app', 'appSettings', 'staticContentServiceUrl' ]);

export const getFeatureToggles = (state: Map<*, *>) => state.getIn([ 'app', 'appSettings', 'featureToggle' ]);

export const isAppReady = (state: Map<*, *>) => {
  return !!state.getIn([ 'app', 'fetchUserDone' ]);
};

export const getEnduranceSignUpLink = (state: Map<*, *>) => state.getIn([ 'app', 'appSettings', 'enduranceSettings', 'enduranceSignUpUrl' ]);

export const getPortalPath = (state: Map<*, *>) => state.getIn([ 'app', 'portalPath' ]);

export const hasUsedPortalPath = createSelector(getPortalPath, portalPath => !!(portalPath && portalPath.get('portalType') && portalPath.get('portalId')));
