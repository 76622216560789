// @flow

import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { getEntities, getById, mapToEntities } from 'modules/selector';
import { getResults } from 'modules/result/selector';
import { getGroupRankEntities } from 'modules/groupRank/selector';
import { getEvents } from 'modules/event/selector';
import { getRoutes } from 'modules/route/selector';

export const getParticipantEntities = createSelector(
  [ getEntities('participants'), getEntities('persons') ],
  (participants: Map<string, *>, persons: Map<string, *>) => participants.map(participant => participant.set('person', getById(persons, participant.get('person')))),
);

export const getParticipants = createSelector(
  [ getEvents, getRoutes, getGroupRankEntities, getParticipantEntities, getResults, getEntities('splitResults') ],
  (
    events: Map<string, *>,
    routes: Map<string, *>,
    groupRanks: Map<string, *>,
    participants: Map<string, *>,
    results: Map<string, *>,
    splitResults: Map<string, *>,
  ) => participants.map(
    participant => participant
      .set('participatedEvent', getById(events, participant.get('participatedEvent')))
      .set('participatedRoute', getById(routes, participant.get('participatedRoute')))
      .set('finalResult', getById(results, participant.get('finalResult')))
      .update('groupRanks', mapToEntities(groupRanks))
      .update('splitResults', mapToEntities(splitResults)),
  ),
);
