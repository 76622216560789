// @flow

import usMessages from 'locale/en_US.json';
import langugeMessage from 'locale/messages.json';

const DEFAULT_LOCALE = 'en_US';
let messages = {};

const importMessage = (locale: string): Object => {
  return locale === DEFAULT_LOCALE
    ? new Promise(resolve => resolve(usMessages))
    : import(/* webpackChunkName: "locale/[request]" */ `locale/${locale}.json`);
};

export const loadMessages = (locale: string) => {
  return importMessage(locale).then((m) => {
    messages = {
      ...m,
      ...langugeMessage,
    };
    return messages;
  });
};

export const getMessage = (key: string) => {
  return messages[key] || usMessages[key];
};
