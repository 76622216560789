// @flow

export const MeasurementUnit = {
  KM: 'KM',
  MI: 'Mile',
};

export const EventImage = {
  LOGO: 'LOGO',
  HERO: 'HERO',
};
