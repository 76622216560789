// @flow
import compose from 'lodash/fp/compose';

const HOUR_TO_MILLISECONDS = 60 * 60 * 1000;

const ONE_MILE_TO_KILOMETERS = 1.609344;

const MI_UNIT = 'MI';

const DEFAULT_SCALAR = 2;

const calculatedScalar = (scalar: number = DEFAULT_SCALAR) => scalar;

const calculatePace = (speed: number) => {
  return speed ? Math.round((HOUR_TO_MILLISECONDS * 10000) / speed) / 10000 : 0;
};

export const kilometersToMiles = (kilometers: number) => {
  return parseFloat(kilometers) / ONE_MILE_TO_KILOMETERS;
};

export const transKilometersAsUnit = (kilometers: number, unit: string, scalar?: number): number => {
  return parseFloat((MI_UNIT === unit ? kilometersToMiles(kilometers) : kilometers).toFixed(calculatedScalar(scalar)));
};

export const speed2Pace = (speed: number, unit: string) => {
  return compose(calculatePace, transKilometersAsUnit)(speed, unit, 4);
};
