// @flow

import prop from 'lodash/fp/prop';
import flow from 'lodash/fp/flow';
import toLower from 'lodash/fp/toLower';
import eq from 'lodash/fp/eq';
import { parse } from 'query-string';
import type { History } from 'type/type';

import { getKeyFromQuery } from '../query/query';

export const APP_MODE_KIOSK = 'kiosk';

export const isKioskMode = (history: History) => {
  const query = flow(
    prop('location.search'),
    parse,
  )(history);
  const appModeKey = getKeyFromQuery()({ key: 'appmode', query });
  return flow(
    prop(appModeKey),
    toLower,
    eq(APP_MODE_KIOSK),
  )(query);
};
