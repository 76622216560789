// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import type { Language } from 'type/type';

import './LanguageSelectorList.scss';

type Props = {
  languages: Language[],
  currentLanguage: ?string,
  languageItemClass?: ?string,
  handleItemClick: Function
}

class LanguageSelectorList extends Component<Props> {
  static defaultProps = {
    languageItemClass: null,
  }

  render() {
    const {
      languages, currentLanguage, languageItemClass, handleItemClick,
    } = this.props;
    return (
      <div className="language-selector-list">
        {languages && languages.map((language) => {
          const { value, text } = language;
          const selected = value === currentLanguage;

          return (
            <div
              key={value}
              className={classNames(
                'language-selector-list-item',
                {
                  selected,
                },
                languageItemClass)}>
              { selected && <i className="icon-check-thin" />}
              <span onClick={() => handleItemClick(language)}>{ text }</span>
            </div>
          );
        })}
      </div>
    );
  }
}

export default LanguageSelectorList;
