// @flow

import { Map } from 'immutable';
import { normalize } from 'normalizr';
import ParticipantEntity from 'modules/participant/entity';
import type {
  Participant, Action, Issue, NoPayloadAction, ParticipantPhotos, ImageItem,
} from 'type/type';

// Action types

export const MOUNT = 'results/participant/MOUNT';
export const FETCH_PARTICIPANT_DONE = 'results/participant/FETCH_PARTICIPANT_DONE';
export const REPORT_ISSUE = 'results/participant/REPORT_ISSUE';
export const REPORT_ISSUE_SUCCESS = 'results/participant/REPORT_ISSUE_SUCCESS';
export const REPORT_ISSUE_FAILURE = 'results/participant/REPORT_ISSUE_FAILURE';
export const FETCH_PARTICIPANT_FAILED = 'results/participant/FETCH_PARTICIPANT_FAILED';
export const FETCH_PARTICIPANT = 'results/participant/FETCH_PARTICIPANT';
export const SET_SHOW_GALLERY = 'results/participant/SET_SHOW_GALLERY';
export const FETCH_PARTICIPANT_PHOTO = 'results/participant/FETCH_PARTICIPANT_PHOTO';
export const UPDATE_PARTICIPANT_PHOTOS = 'results/participant/UPDATE_PARTICIPANT_PHOTOS';
export const FETCH_PARTICIPANT_PHOTO_FAILED = 'results/participant/FETCH_PARTICIPANT_PHOTO_FAILED';

// Action creators

export const mount = (participantId: string) => ({
  type: MOUNT,
  payload: {
    participantId,
  },
});

export const fetchParticipant = (participantId: string) => ({
  type: FETCH_PARTICIPANT,
  payload: {
    participantId,
  },
});

export const fetchParticipantDone = (participant: Participant) => ({
  type: FETCH_PARTICIPANT_DONE,
  payload: normalize(participant, ParticipantEntity),
});

export const reportIssue = (value: Issue) => ({
  type: REPORT_ISSUE,
  payload: value,
});

export const fetchParticipantFailed = () => ({
  type: FETCH_PARTICIPANT_FAILED,
});

export const reportIssueFailure = (): NoPayloadAction => ({
  type: REPORT_ISSUE_FAILURE,
});

export const reportIssueSuccess = (): NoPayloadAction => ({
  type: REPORT_ISSUE_SUCCESS,
});

export const setShowGallery = (showGallery: boolean) => ({
  type: SET_SHOW_GALLERY,
  payload: {
    showGallery,
  },
});

export const fetchParticipantPhoto = (
  participantId: string, externalId: string, lastId: ?string, prevPhotos: ?Array<ImageItem>,
) => ({
  type: FETCH_PARTICIPANT_PHOTO,
  payload: {
    participantId,
    externalId,
    lastId,
    prevPhotos,
  },
});

export const updateParticipantPhotos = (participantPhotos: ParticipantPhotos) => ({
  type: UPDATE_PARTICIPANT_PHOTOS,
  payload: normalize(participantPhotos, ParticipantEntity),
});

export const fetchParticipantPhotoFailed = (): NoPayloadAction => ({
  type: FETCH_PARTICIPANT_PHOTO_FAILED,
});

// reducer

const initialState = Map({
  isLoadingParticipant: false,
  isFetchParticipantFailed: false,
  showGallery: false,
  isLoadingParticipantPhoto: false,
});

export const reducer = (state: Map<string, *> = initialState, action: Action) => {
  switch (action.type) {
    case MOUNT:
      return state.set();
    case FETCH_PARTICIPANT:
      return state.set('isLoadingParticipant', true)
        .set('isFetchParticipantFailed', false);
    case FETCH_PARTICIPANT_DONE:
      return state
        .set('participantId', action.payload.result)
        .set('isLoadingParticipant', false)
        .set('isFetchParticipantFailed', false);
    case FETCH_PARTICIPANT_FAILED:
      return state.set('isLoadingParticipant', false)
        .set('isFetchParticipantFailed', true);
    case REPORT_ISSUE:
      return state.set('savingIssue', true);
    case REPORT_ISSUE_FAILURE:
      return state.set('savingIssue', false);
    case REPORT_ISSUE_SUCCESS:
      return state.set('savingIssue', false);
    case SET_SHOW_GALLERY:
      return state.set('showGallery', action.payload.showGallery);
    case FETCH_PARTICIPANT_PHOTO:
      return state.set('isLoadingParticipantPhoto', true);
    case UPDATE_PARTICIPANT_PHOTOS:
      return state.set('isLoadingParticipantPhoto', false);
    case FETCH_PARTICIPANT_PHOTO_FAILED:
      return state.set('isLoadingParticipantPhoto', false);
    default:
      return state;
  }
};
