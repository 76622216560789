// @flow

import identity from 'lodash/identity';
import { Map, fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { getEntities, getById } from 'modules/selector';
import { getParticipants } from 'modules/participant/selector';
import { ENTITY_NAME as EVENT } from 'modules/event/entity';
import { ENTITY_NAME as PARTICIPANT } from 'modules/participant/entity';
import { ENTITY_NAME as ROUTESTAGES } from 'modules/routeStage/entity';
import Maybe, { maybe } from '@active/awe-ui-core/lib/fp/Maybe';
import { speed2Pace, kilometersToMiles } from 'services/newton';
import { formatedDurationByMilliseconds } from 'services/format/formatDuration';
import getFinalResult from 'services/getFinalResult/getFinalResult';

const DEFAULT_SCALAR = 2;
const DIFAULT_UNIT = 'KM';

const calculatedScalar = (scalar: number = DEFAULT_SCALAR) => scalar;

export const getParticipantId = (state: any) => state.getIn([ 'participantPage', 'participantId' ]);

export const getParticipantEntity = createSelector(
  [ getEntities(PARTICIPANT), getParticipantId ],
  (participants: Map<string, *>, id: number) => getById(participants, id),
);

export const getParticipant = createSelector(
  [ getParticipants, getParticipantId ],
  (participants: Map<*, *>, id: number) => Maybe.of(getById(participants, id)),
);

export const getParticipantExternalId = createSelector([ getParticipant ], (participant: Map<string, *>) => maybe(undefined, id => id, participant.map(p => p.get('externalId'))),
);

export const isTriathlonResult = createSelector(
  [ getParticipant ],
  (participant: Map<string, *>) => maybe(
    false,
    stages => stages.size > 1,
    participant.map(p => p.getIn([ 'participatedRoute', 'stages' ])),
  ),
);

const getParticipatedSplits = createSelector([ getParticipant ], (participant: Map<string, *>) => maybe(
  fromJS([]),
  stages => stages.flatMap(stage => stage && stage
    .get('routeStagePoints')
    .map(routeStagePoint => routeStagePoint.set('stageSequence', stage.get('sequence'))),
  ),
  participant.map(p => p.getIn([ 'participatedRoute', 'stages' ])),
),
);

const getSplitResults = createSelector([ getParticipant ], (participant: Map<string, *>) => maybe(
  fromJS([]),
  identity,
  participant.map(p => p.get('splitResults')),
),
);

export const getParticipantHeader = createSelector(
  [ getParticipant ],
  (participant: Map<string, *>) => maybe(
    fromJS({
      person: {},
      groupRanks: [],
      finalResult: {
        displayPace: '',
      },
      participatedRoute: {},
    }),
    headerData => headerData,
    participant),
);

export const getEvent = createSelector(
  [ getEntities(EVENT), getParticipantEntity ],
  (events: Map<string, *>, participant: Map<string, *> = new Map()) => getById(events, participant.get('participatedEvent')),
);

export const getSplitWithResults = createSelector(
  [ getParticipatedSplits, getSplitResults, getEntities(ROUTESTAGES) ],
  (splits: Map<string, *>, results: Map<string, *>, routeStages: Map<string, *>) => splits.map(split => split.set(
    'result',
    results.find(r => r.get('routeStagePointId') === split.get('id')),
  ).set(
    'stageType',
    routeStages.find(stage => stage.get('routeStagePoints').includes(split.get('id')), null, new Map()).get('type'),
  )),
);

export const getEventTimeZone = createSelector([ getEvent ], (event: Map<string, *> = new Map()) => event.get('timeZone'));

export const isLoadingParticipant = (state: Map<string, *>) => state.getIn([ 'participantPage', 'isLoadingParticipant' ]);

export const isSavingIssue = (state: Map<string, *>) => state.getIn([ 'participantPage', 'savingIssue' ]);

export const isLiveResult = createSelector(
  [ getParticipant, getSplitResults ],
  (participant: Map<string, *>, results: Map<string, *>) => maybe(
    true,
    data => !data.getIn([ 'finalResult', 'finalResult' ]) && !data.getIn([ 'finalResult', 'chipTimeResult' ]) && !data.getIn([ 'finalResult', 'gunTimeResult' ]),
    participant,
  ) && results.filter(r => r.get('missing') || r.get('result') !== null).size > 0,
);

export const isFetchParticipantFailed = (state: Map<string, *>) => state.getIn([ 'participantPage', 'isFetchParticipantFailed' ]);

const getTimeFlag = createSelector([ getParticipant ], (participant: Map<string, *>) => maybe(
  false,
  useChipTime => useChipTime,
  participant.map(p => p.getIn([ 'participatedRoute', 'useChipTime' ])),
),
);

const getResultStages = createSelector([ getParticipant ], (participant: Map<string, *>) => maybe(
  fromJS([]),
  stages => stages,
  participant.map(p => p.getIn([ 'participatedRoute', 'stages' ])),
),
);

function createMissingRouteStageResult(stage, participant) {
  return Map({
    routeStageId: stage.get('id'),
    participantId: participant.get('id'),
  });
}

export const getRouteStageResults = createSelector([ getParticipant, getTimeFlag ],
  (participant: Map<string, *>, useChipTime: boolean) => maybe(
    fromJS([]),
    (p) => {
      return p.getIn([ 'participatedRoute', 'stages' ]).map((stage) => {
        const routeStageResult = p.getIn([ 'finalResult', 'routeStageResults' ])
          .find(rsr => rsr.get('routeStageId') === stage.get('id'))
            || createMissingRouteStageResult(stage, p);
        return routeStageResult
          .set('splitTime', getFinalResult(useChipTime, routeStageResult))
          .set('distance', stage.get('distance'))
          .set('distanceUnit', stage.get('distanceUnit') || DIFAULT_UNIT);
      });
    },
    participant),
);

const getGroupRanks = createSelector([ getParticipant ], (participant: Map<string, *>) => maybe(
  fromJS([]),
  groupRanks => groupRanks,
  participant.map(p => p.get('groupRanks')),
),
);

export const getParticipantSummaryResults = createSelector(
  [ getResultStages, getRouteStageResults, getGroupRanks ],
  (stages: Map<string, *>, results: Map<string, *>, ranks: Map<string, *>) => stages.map(stage => stage
      && stage.set('result', results.find(r => r.get('routeStageId') === stage.get('id')) || {})
        .set('rank', ranks.filter(r => r.get('stageId') === stage.get('id'))),
  ),
);

export const getResultPace = ({
  speed, distanceUnit, stageType, scalar,
}) => {
  if (stageType === 'SWIMMING') {
    return ({
      value: formatedDurationByMilliseconds(speed2Pace(parseFloat(speed) * 10, 'KM'), undefined, true),
      unit: ' /hm',
    });
  }

  if (stageType === 'CYCLING') {
    if (distanceUnit === 'MI') {
      return ({
        value: parseFloat(kilometersToMiles(speed).toFixed(calculatedScalar(scalar))),
        unit: ' mph',
      });
    }

    return ({
      value: parseFloat(parseFloat(speed).toFixed(calculatedScalar(scalar))),
      unit: ' kph',
    });
  }

  return ({
    value: formatedDurationByMilliseconds(speed2Pace(speed, distanceUnit), undefined, true),
    unit: ` /${distanceUnit.toLowerCase()}`,
  });
};

const displayPaceExp = /(\S*\d+)\s*(\D*)$/i;

const formatDisplayPace = ({ displayPace }) => {
  const results = displayPaceExp.exec(displayPace);
  if (results) {
    return ({
      value: results[1],
      unit: results[2],
    });
  }
  return ({
    value: displayPace,
    unit: '',
  });
};

export const getFinalResultPace = (formatMessage, { distanceUnit, averageSpeed, displayPace }) => {
  if (displayPace) {
    return formatDisplayPace({
      displayPace,
    });
  }
  return ({
    value: averageSpeed >= 0 && distanceUnit ? formatedDurationByMilliseconds(speed2Pace(averageSpeed, distanceUnit), undefined, true) : '--',
    unit: distanceUnit ? `/${formatMessage(`app.domain.result.distance.unit.${distanceUnit}`)}` : '--',
  });
};

export const getStageResultPace = ({ result: { displayPace, averageSpeed }, type, distanceUnit }) => {
  let paceData = {};
  if (displayPace) {
    paceData = formatDisplayPace({
      displayPace,
    });
  } else if (averageSpeed) {
    paceData = getResultPace({
      speed: averageSpeed,
      distanceUnit,
      stageType: type,
    });
  }
  return paceData;
};

export const getShowGallery = (state: any) => state.getIn([ 'participantPage', 'showGallery' ]);

export const getParticipantPhoto = createSelector(
  [ getParticipant ],
  (participant: Map<string, *>) => maybe(
    [],
    photoURL => photoURL,
    participant.map(p => p.getIn([ 'participantCustomFields', 'photoURL' ])),
  ),
);

export const isLoadingParticipantPhoto = (state: Map<string, *>) => state.getIn([ 'participantPage', 'isLoadingParticipantPhoto' ]);
