// @flow
import { schema } from 'normalizr';
import type { Route } from 'type/type';

import GroupEntity from '../group/entity';
import RouteStageEntity from '../routeStage/entity';

export const ENTITY_NAME = 'routes';

export default new schema.Entity(ENTITY_NAME, {
  groups: [ GroupEntity ],
  stages: [ RouteStageEntity ],
}, {
  processStrategy: (v: Route) => ({ ...v, id: v.id && v.id.toString() }),
});
