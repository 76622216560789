// @flow

import compose from 'lodash/fp/compose';
import prop from 'lodash/fp/prop';
import isArray from 'lodash/isArray';
import configAjax from '@active/awe-ui-core/lib/services/configAjax';
import map from '@active/awe-ui-core/lib/fp/map';
import Maybe, { maybe } from '@active/awe-ui-core/lib/fp/Maybe';
import { appError } from 'modules/app/duck';

type XHRError = {
  status: number,
  message: ?string
}

const ACCEPT = 'application/vnd.active.results-service.v1+json';
const REACT_APP_API_MOCK = 'mock';

const mProp = (property: string) => map(prop(property));
const getResponse = compose(map(resp => (isArray(resp) ? resp[0] : resp)), mProp('response'), Maybe.of);

const ajax = configAjax({
  prefix: process.env.REACT_APP_API === REACT_APP_API_MOCK ? '/mock' : '/api',
  error: (error: XHRError) => appError(error.status, maybe(null, prop('message'), getResponse(error))),
  defaultConfig: {
    headers: {
      accept: ACCEPT,
    },
  },
});

export default ajax;
