// @flow

import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

const defaultPartern = 'HH[:]mm[:]ss';

momentDurationFormatSetup(moment);

export const formatedDuration = (durationTime?: string, partern?: string, isFormatingPace?: boolean) => {
  return moment.duration(durationTime).format(partern || defaultPartern, {
    forceLength: true,
    trunc: true,
    stopTrim: isFormatingPace ? 'mm' : 'HH',
  });
};

export const formatedDurationByMilliseconds = (milliseconds: number, partern?: string, isFormatingPace?: boolean) => {
  return moment.duration(milliseconds, 'milliseconds').format(partern || defaultPartern, {
    forceLength: true,
    trunc: true,
    stopTrim: isFormatingPace ? 'mm' : 'HH',
  });
};
