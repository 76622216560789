// @flow

import { fromJS, Map } from 'immutable';
import type { Action, PortalPath } from 'type/type';
import { FETCH_USER_FULFILLED } from '../user/duck';

// Action type
export const APP_ERROR = 'results/app/APP_ERROR';

export const CHANGE_LANGUAGE = 'results/app/CHANGE_LANGUAGE';
export const LAUNCH = 'results/app/Launch';
export const FETCH_APP_SETTINGS_FULFILLED = 'results/app/FETCH_APP_SETTINGS_FULFILLED';

// Actions
export const appError = (httpCode: number, message: ?string) => {
  return {
    type: APP_ERROR,
    payload: {
      httpCode,
      message,
    },
  };
};

export const changeLanguage = (language: string) => ({
  type: CHANGE_LANGUAGE,
  payload: {
    language,
  },
});

export const launch = (locale: string, portalPath: PortalPath) => ({
  type: LAUNCH,
  payload: {
    defaultLanguage: locale,
    portalPath,
  },
});

export const fetchAppSettingsFulFilled = (serviceProperties: Object) => {
  const { availableLanguages, ...props } = serviceProperties;
  return {
    type: FETCH_APP_SETTINGS_FULFILLED,
    payload: {
      ...props,
      availableLanguages,
    },
  };
};

export const initialState = fromJS({
  locale: 'en_US',
  fetchUserDone: false,
});

export const reducer = (state: Map<*, *> = initialState, action: Action) => {
  switch (action.type) {
    case LAUNCH:
      return state.set('fetchUserDone', false)
        .set('locale', action.payload.defaultLanguage || initialState.get('locale'))
        .set('portalPath', fromJS(action.payload.portalPath));
    case FETCH_USER_FULFILLED:
      return state.set('fetchUserDone', true);
    case CHANGE_LANGUAGE: {
      return state.set('locale', action.payload.language);
    }
    case FETCH_APP_SETTINGS_FULFILLED: {
      const { availableLanguages, ...appSettings } = action.payload;
      return state.set('availableLanguages', availableLanguages).set('appSettings', fromJS(appSettings));
    }
    default:
      return state;
  }
};
