// @flow

import { schema } from 'normalizr';
import RouteStageResultEntity from '../routeStageResult/entity';

export const ENTITY_NAME = 'results';

const finalResultsId = (result, participantId) => `finalResult_${result.participantId || participantId}`;

export default new schema.Entity(ENTITY_NAME, {
  routeStageResults: [ RouteStageResultEntity ],
}, {
  idAttribute: (value, participant) => {
    return value.id || finalResultsId(value, participant && participant.id);
  },
  processStrategy: (r, participant) => {
    return {
      ...r,
      id: r.id || finalResultsId(r, participant && participant.id),
      participantId: r.participantId || (participant && participant.id),
    };
  },
});
